var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-contactSch",
            size: "lg",
            title: "Confirm Result",
            "header-bg-variant": "dark",
            "header-text-variant": "light",
            "content-class": "modal-application-content",
            scrollable: "",
          },
          on: {
            hide: () => {
              if (_vm.hasEdited) _vm.$parent.refresh()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "btn-apply",
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _vm.selectedApplication
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-table", {
                            staticClass: "application-modal",
                            attrs: {
                              stacked: "",
                              fields: _vm.fields,
                              items: _vm.getArray(_vm.selectedApplication),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(TotalTickets)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n               " +
                                          _vm._s(
                                            row.item.firstChoiceStudentNo +
                                              row.item.firstChoiceTeacherNo +
                                              row.item.firstChoiceParentNo
                                          ) +
                                          "\n             "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(grandTotal)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n               " +
                                          _vm._s(
                                            row.item.data &&
                                              row.item.data.aes &&
                                              row.item.data.aes.confirmedPrice
                                              ? `$${_vm.getConfirmedPrice(
                                                  row.item.data.aes
                                                    .confirmedPrice
                                                )}`
                                              : ""
                                          ) +
                                          "\n             "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(ApplicationResult)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n               " +
                                          _vm._s(
                                            row.item.data &&
                                              row.item.data.aes &&
                                              row.item.data.aes
                                                .applicationResult
                                              ? `${row.item.data.aes.applicationResult}`
                                              : ""
                                          ) +
                                          "\n             "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1574393552
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-col", [
                        _c("table", { attrs: { width: "100%" } }, [
                          _c("tr", [
                            _c(
                              "th",
                              [
                                _vm._v(
                                  "\n                 申請狀‍態：\n                 "
                                ),
                                _c("b-icon", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "老師用戶可於「我的申請」中查閱申請狀態",
                                      expression:
                                        "\n                     '老師用戶可於「我的申請」中查閱申請狀態'\n                   ",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  attrs: {
                                    icon: "exclamation-circle-fill",
                                    variant: "warning",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm.isEditStatus
                                ? _c(
                                    "div",
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.statusOptions },
                                        model: {
                                          value: _vm.status,
                                          callback: function ($$v) {
                                            _vm.status = $$v
                                          },
                                          expression: "status",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.getStatusTxt(_vm.status))
                                    ),
                                  ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("付款（Admin專用）：")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm.isEditStatus
                                ? _c(
                                    "div",
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.resultOptions },
                                        model: {
                                          value: _vm.aes.applicationResult,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.aes,
                                              "applicationResult",
                                              $$v
                                            )
                                          },
                                          expression: "aes.applicationResult",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "field" }, [
                                    _vm._v(_vm._s(_vm.aes.applicationResult)),
                                  ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.isEditStatus
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2", align: "right" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.submitStatus },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   Submit\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      { on: { click: _vm.cancelStatus } },
                                      [_vm._v("Cancel")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : !_vm.isEditPrice && !_vm.isEditCheque
                            ? _c("tr", [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { align: "left" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.AES_SUCCESS",
                                            modifiers: { AES_SUCCESS: true },
                                          },
                                        ],
                                        staticClass: "mt-2",
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-secondary",
                                          disable: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   發送成功通知\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.AES_WAITING",
                                            modifiers: { AES_WAITING: true },
                                          },
                                        ],
                                        staticClass: "mt-2",
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-warning",
                                          disable: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   發送候補通知\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.AES_CANCEL",
                                            modifiers: { AES_CANCEL: true },
                                          },
                                        ],
                                        staticClass: "mt-2",
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-info",
                                          disable: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   發送取消通知\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "right",
                                        attrs: { variant: "link", size: "sm" },
                                        on: {
                                          click: () => {
                                            _vm.isEditStatus = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   編輯\n                 "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [_c("hr")]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("免費老師門票數‍目：")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditPrice
                                  ? _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        readonly: !_vm.hasFreeTeacherTicket,
                                        min: 0,
                                        max: _vm.selectedApplication
                                          .firstChoiceTeacherNo,
                                        state: _vm.freeTeacherNoState(),
                                      },
                                      model: {
                                        value: _vm.aes.freeTeacherNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.aes,
                                            "freeTeacherNo",
                                            $$v
                                          )
                                        },
                                        expression: "aes.freeTeacherNo",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.freeTeacherNo)),
                                    ]),
                                _vm._v(" "),
                                !_vm.hasFreeTeacherTicket
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                   活動沒有 free teacher ticket\n                 "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(
                                "\n                 電腦估算結‍果：\n                 "
                              ),
                              _c("p", [
                                _vm._v(
                                  "\n                   " +
                                    _vm._s(
                                      _vm.getFormulaTxt(
                                        _vm.selectedApplication
                                          .firstChoiceStudentNo,
                                        _vm.selectedApplication
                                          .firstChoiceTeacherNo,
                                        _vm.selectedApplication
                                          .firstChoiceParentNo,
                                        _vm.selectedApplication.event_slot_1,
                                        _vm.aes.freeTeacherNo
                                      )
                                    ) +
                                    "\n                   = $" +
                                    _vm._s(
                                      _vm.calFee(
                                        _vm.selectedApplication,
                                        _vm.selectedApplication.event_slot_1,
                                        _vm.aes.freeTeacherNo
                                      )
                                    ) +
                                    "\n                 "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("確認金額（HKD）：")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditPrice
                                  ? _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "請輸入確認金額...",
                                      },
                                      model: {
                                        value: _vm.aes.confirmedPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.aes,
                                            "confirmedPrice",
                                            $$v
                                          )
                                        },
                                        expression: "aes.confirmedPrice",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(
                                        "\n                   " +
                                          _vm._s(
                                            _vm.aes.confirmedPrice
                                              ? `$${_vm.getConfirmedPrice(
                                                  _vm.aes.confirmedPrice
                                                )}`
                                              : ""
                                          ) +
                                          "\n                 "
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.isEditPrice
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2", align: "right" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.submitAes },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   Submit\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      { on: { click: _vm.cancelAes } },
                                      [_vm._v("Cancel")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : !_vm.isEditStatus && !_vm.isEditCheque
                            ? _c("tr", [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { align: "left" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.AES_PAYMENT",
                                            modifiers: { AES_PAYMENT: true },
                                          },
                                        ],
                                        staticClass: "mt-2",
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-secondary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   發送付款通知\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "right mt-2",
                                        attrs: { variant: "link", size: "sm" },
                                        on: {
                                          click: () => {
                                            _vm.isEditPrice = true
                                          },
                                        },
                                      },
                                      [_vm._v("編輯")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [_c("hr")]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("支票")]),
                            _vm._v(" "),
                            _c("td"),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("支票抬頭：")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditCheque
                                  ? _c("b-form-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.aes.cheque,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.aes, "cheque", $$v)
                                        },
                                        expression: "aes.cheque",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.cheque)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Bank:")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditCheque
                                  ? _c("b-form-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.aes.bank,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.aes, "bank", $$v)
                                        },
                                        expression: "aes.bank",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.bank)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Cheque No:")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditCheque
                                  ? _c("b-form-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.aes.chequeno,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.aes, "chequeno", $$v)
                                        },
                                        expression: "aes.chequeno",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.chequeno)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Amount:")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditCheque
                                  ? _c("b-form-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.aes.amount,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.aes, "amount", $$v)
                                        },
                                        expression: "aes.amount",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.amount)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Receive Date:")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.isEditCheque
                                  ? _c("b-form-input", {
                                      attrs: { type: "date" },
                                      model: {
                                        value: _vm.aes.receiveDate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.aes, "receiveDate", $$v)
                                        },
                                        expression: "aes.receiveDate",
                                      },
                                    })
                                  : _c("div", { staticClass: "field" }, [
                                      _vm._v(_vm._s(_vm.aes.receiveDate)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.isEditCheque
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2", align: "right" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.submitAes },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   Submit\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      { on: { click: _vm.cancelAes } },
                                      [_vm._v("Cancel")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : !_vm.isEditStatus && !_vm.isEditPrice
                            ? _c("tr", [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { align: "left" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.AES_CONFIRM",
                                            modifiers: { AES_CONFIRM: true },
                                          },
                                        ],
                                        staticClass: "mt-2",
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-secondary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                   發送確認通知\n                 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "right mt-2",
                                        attrs: { variant: "link", size: "sm" },
                                        on: {
                                          click: () => {
                                            _vm.isEditCheque = true
                                          },
                                        },
                                      },
                                      [_vm._v("編輯")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.selectedApplication
        ? _c(
            "div",
            { key: _vm.selectedApplication.id },
            [
              _vm.selectedApplication
                ? _c("email-modal", {
                    key: _vm.selectedApplication.id,
                    attrs: {
                      "trn-id": _vm.selectedApplication.trnId,
                      code: "AES_SUCCESS",
                      "custom-api": _vm.getCustomApi(
                        _vm.cloudApiUrl + "contacts/aes/contact/",
                        _vm.selectedApplication.event_id
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedApplication
                ? _c("email-modal", {
                    attrs: {
                      "trn-id": _vm.selectedApplication.trnId,
                      code: "AES_WAITING",
                      "custom-api": _vm.getCustomApi(
                        _vm.cloudApiUrl + "contacts/aes/contact/",
                        _vm.selectedApplication.event_id
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedApplication
                ? _c("email-modal", {
                    attrs: {
                      "trn-id": _vm.selectedApplication.trnId,
                      code: "AES_PAYMENT",
                      "custom-data": _vm.getCustomData(_vm.aes),
                      "custom-api": _vm.getCustomApi(
                        _vm.cloudApiUrl + "contacts/aes/contact/",
                        _vm.selectedApplication.event_id
                      ),
                      "has-free-teacher-ticket": _vm.hasFreeTeacherTicket,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedApplication
                ? _c("email-modal", {
                    attrs: {
                      "trn-id": _vm.selectedApplication.trnId,
                      code: "AES_CONFIRM",
                      "custom-api": _vm.getCustomApi(
                        _vm.cloudApiUrl + "contacts/aes/contact/",
                        _vm.selectedApplication.event_id
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedApplication
                ? _c("email-modal", {
                    attrs: {
                      "trn-id": _vm.selectedApplication.trnId,
                      code: "AES_CANCEL",
                      "custom-api": _vm.getCustomApi(
                        _vm.cloudApiUrl + "contacts/aes/contact/",
                        _vm.selectedApplication.event_id
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }