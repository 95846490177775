<template>
  <footer>
    <b-row class="align-middle">
      <b-col cols="4">
        <b-row>
          <ul>
            <li>
              <nuxt-link :to="localePath('/notice')">
                {{ i18nName(noticeData) }}
              </nuxt-link>
            </li>
            <li>|</li>
            <li>
              <a
                href="https://www.lcsd.gov.hk/tc/info_pdo.html"
                target="_blank"
                title="私隱政策"
                >{{ getTxt('privacy') }}</a
              >
            </li>
          </ul>
        </b-row>
        <b-row>
          <ul>
            <li>{{ getTxt('lastmodified') }} {{ lastmodified }}</li>
          </ul>
        </b-row>
      </b-col>
      <b-col cols="5"></b-col>
      <b-col cols="3">
        <div id="wcag2aa" style="text-align: right; padding-right: 18px">
          <a :href="getLcsdLink">
            <img
              height="24"
              src="https://www.abo.gov.hk/image/lcsd_edutainment_logo.jpeg"
              alt="LCSD Icon"
              style="max-width: 100px"
            />
          </a>
          <a :href="getPrdcultureLink" target="_blank">
            <img
              height="24"
              :src="getPrdcultureImg"
              alt="Prdculture Icon"
              style="max-width: 100px"
            />
          </a>
          <a
            id="footerLogoAA"
            href="https://www.w3.org/WAI/WCAG2AA-Conformance"
            target="_blank"
            title="符合萬維網聯盟有關無障礙網頁設計指引中2A級別的要求"
            rel="external"
          >
            <img
              height="24"
              src="https://www.w3.org/WAI/wcag21/wcag2.1AA-v.png"
              alt="符合萬維網聯盟有關無障礙網頁設計指引中2A級別的要求"
              style="max-width: 100px"
            />
          </a>
          <a href="https://www.brandhk.gov.hk/">
            <img
              height="24"
              :src="getHKImg"
              alt="bankHK Icon"
              style="max-width: 100px"
            />
          </a>
        </div>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  props: {
    myProp: {
      type: String,
      default: process.env.lastmodified,
    },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}pages/public/notice`
      )
      this.noticeData = result.data.data
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      noticeData: {},
      lastmodified: null,
    }
  },
  mounted() {
    console.log('----------------')
    console.log(this.myProp)
    console.log('----------------')
    const convertedDate = new Date(this.myProp)

    const adjustedDate = convertedDate.toLocaleString('en-US', {
      timeZone: 'Asia/Hong_Kong',
    })
    this.lastmodified = new Date(adjustedDate)
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '.')
  },
  computed: {
    getLcsdLink() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'https://www.lcsd.gov.hk/edutainment/en/'
        case 'sc':
          return 'https://www.lcsd.gov.hk/edutainment/sc/'
        default:
          return 'https://www.lcsd.gov.hk/edutainment/tc/'
      }
    },
    getPrdcultureLink() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'https://www.prdculture.org.cn/ygawlzxwen/index.shtml'
        default:
          return 'https://www.prdculture.org.cn/'
      }
    },
    getPrdcultureImg() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'https://www.abo.gov.hk/image/prdculture_logo-en.png'
        case 'sc':
          return 'https://www.abo.gov.hk/image/prdculture_logo-sc.png'
        default:
          return 'https://www.abo.gov.hk/image/prdculture_logo-tc.png'
      }
    },
    getHKImg() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'https://www.abo.gov.hk/image/hklogo-en.svg'
        case 'sc':
          return 'https://www.abo.gov.hk/image/hklogo-sc.svg'
        default:
          return 'https://www.abo.gov.hk/image/hklogo-tc.svg'
      }
    },
  },
  methods: {
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    getTxt(field) {
      const lang =
        this.$i18n.locale === 'en' ? 0 : this.$i18n.locale === 'tc' ? 1 : 2
      switch (field) {
        case 'home':
          return lang === 0 ? 'Home' : lang === 1 ? '主頁' : '主页'
        case 'privacy':
          return lang === 0
            ? 'Privacy Policy'
            : lang === 1
            ? '私隱政策'
            : '私隐政策'
        case 'lastmodified':
          return lang === 0
            ? 'Last Modified: '
            : lang === 1
            ? '最後修訂日期：'
            : '最后修订日期：'
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
footer {
  background: #ecebeb;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 75px;
  bottom: 0;
  border-top: 2px solid #e8e8e8;
  padding: 15px 0;
}
footer ul {
  list-style-type: none;
  margin-block-end: 0;
  width: auto;
  li {
    display: inline-block;
    float: none;
    box-sizing: border-box;
    margin-top: 0;
    padding-right: 5px;
    margin-right: 3px;
    position: relative;
    margin-bottom: 0;
    color: rgba(100, 100, 100, 1);
    a {
      color: rgba(100, 100, 100, 1);
    }
  }
}
.footer_icon {
  margin-bottom: 10px;
}
.print {
  display: none !important;
}
footer #backToTop {
  content: '';
  width: 11px;
  height: 16px;
  /* background: transparent url(../images/common_icons/grey/icon_arrow2_up_grey.png) no-repeat center top; */
  background: rgba(255, 255, 255, 0)
    url(https://www.gov.hk/images/common_icons/grey/icon_arrow2_up_grey.svg)
    no-repeat center top;
  background-size: 100%;
  display: inline-block;
  margin: 0 3px;
  vertical-align: bottom;
}
* {
  font-size: 15px;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  footer {
    background: #ecebeb;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 80px;
    bottom: 0;
    border-top: 2px solid #e8e8e8;
    padding: 15px 0;
  }

  footer ul {
    width: 100%;
    padding-left: 0;
  }

  footer .row .col-4 {
    flex: none;
    max-width: unset;
  }
}
</style>
