export { default as Logo } from '../../components/Logo.vue'
export { default as Footer } from '../../components/footer.vue'
export { default as Navbar } from '../../components/navbar.vue'
export { default as AdminNavbar } from '../../components/admin/adminNavbar.vue'
export { default as Editor } from '../../components/admin/editor.vue'
export { default as TinymceEditor } from '../../components/admin/tinymceEditor.vue'
export { default as TinymceEditorFlexible } from '../../components/admin/tinymceEditorFlexible.vue'
export { default as Veditor } from '../../components/admin/veditor.vue'
export { default as Tab0 } from '../../components/apply/tab0.vue'
export { default as Tab1 } from '../../components/apply/tab1.vue'
export { default as Tab2 } from '../../components/apply/tab2.vue'
export { default as Tab3 } from '../../components/apply/tab3.vue'
export { default as EventCard } from '../../components/common/eventCard.vue'
export { default as EventCardForCalendar } from '../../components/common/event_card_for_calendar.vue'
export { default as IconSvg } from '../../components/common/iconSvg.vue'
export { default as Notification } from '../../components/common/notification.vue'
export { default as NotificationBox } from '../../components/common/notification_box.vue'
export { default as RoutePath } from '../../components/common/routePath.vue'
export { default as Schedule } from '../../components/common/schedule.vue'
export { default as Tools } from '../../components/common/tools.vue'
export { default as UserProfile } from '../../components/common/user_profile.vue'
export { default as AoaContent } from '../../components/event/aoaContent.vue'
export { default as EventSlotModal } from '../../components/event/eventSlotModal.vue'
export { default as EventTable } from '../../components/event/eventTable.vue'
export { default as EventTableAdditionalRow } from '../../components/event/eventTableAdditionalRow.vue'
export { default as EventTableForCalendar } from '../../components/event/eventTableForCalendar.vue'
export { default as RedBtn } from '../../components/event/redBtn.vue'
export { default as RemarkBlock } from '../../components/event/remarkBlock.vue'
export { default as ResetBtn } from '../../components/event/resetBtn.vue'
export { default as SpapsTableDetail } from '../../components/event/spapsTableDetail.vue'
export { default as SpapsTableImpl } from '../../components/event/spapsTableImpl.vue'
export { default as FullCalendar } from '../../components/home/fullCalendar.vue'
export { default as NewsModal } from '../../components/home/newsModal.vue'
export { default as Scheme } from '../../components/home/scheme.vue'
export { default as LoginForm } from '../../components/login/loginForm.vue'
export { default as ForgotForm } from '../../components/password/forgotForm.vue'
export { default as ResetForm } from '../../components/password/resetForm.vue'
export { default as SchemeTab } from '../../components/scheme/schemeTab.vue'
export { default as SearchContainer } from '../../components/search/searchContainer.vue'
export { default as Information } from '../../components/setting/information.vue'
export { default as Password } from '../../components/setting/password.vue'
export { default as ApplicationAesForm } from '../../components/admin/application/applicationAesForm.vue'
export { default as ApplicationAllSlots } from '../../components/admin/application/applicationAllSlots.vue'
export { default as ApplicationModal } from '../../components/admin/application/applicationModal.vue'
export { default as ApplicationScdsForm } from '../../components/admin/application/applicationScdsForm.vue'
export { default as ApplicationSpapsForm } from '../../components/admin/application/applicationSpapsForm.vue'
export { default as ApplySchoolInfo } from '../../components/admin/application/applySchoolInfo.vue'
export { default as EmailModal } from '../../components/admin/application/emailModal.vue'
export { default as InfoModal } from '../../components/admin/application/infoModal.vue'
export { default as MatchingSchool } from '../../components/admin/application/matchingSchool.vue'
export { default as SchoolModal } from '../../components/admin/application/schoolModal.vue'
export { default as UserModal } from '../../components/admin/application/userModal.vue'
export { default as Compose } from '../../components/admin/cancelEvent/compose.vue'
export { default as ChangeRequestDetail } from '../../components/admin/change_request/changeRequest_detail.vue'
export { default as NotificationCompose } from '../../components/admin/composeNotification/notificationCompose.vue'
export { default as ConfigForm } from '../../components/admin/config/configForm.vue'
export { default as CustomizeHomepageLastModifyForm } from '../../components/admin/config/customizeHomepageLastModifyForm.vue'
export { default as LcsdRelatedWebForm } from '../../components/admin/config/lcsdRelatedWebForm.vue'
export { default as SchemeTypeForm } from '../../components/admin/config/schemeTypeForm.vue'
export { default as SuggestedEventsForm } from '../../components/admin/config/suggestedEventsForm.vue'
export { default as ContactForm } from '../../components/admin/contact/contactForm.vue'
export { default as EmailForm } from '../../components/admin/email/emailForm.vue'
export { default as EmailTemplateCompose } from '../../components/admin/emailTemplate/emailTemplateCompose.vue'
export { default as EmailTemplateForm } from '../../components/admin/emailTemplate/emailTemplateForm.vue'
export { default as EventForm } from '../../components/admin/event/eventForm.vue'
export { default as FeedbackDetail } from '../../components/admin/feedback/feedbackDetail.vue'
export { default as NewsForm } from '../../components/admin/news/newsForm.vue'
export { default as PageForm } from '../../components/admin/page/pageForm.vue'
export { default as RoleForm } from '../../components/admin/role/roleForm.vue'
export { default as CategoryForm } from '../../components/admin/scheme/categoryForm.vue'
export { default as CommentForm } from '../../components/admin/scheme/commentForm.vue'
export { default as OtherPageForm } from '../../components/admin/scheme/otherPageForm.vue'
export { default as SchemeApplyNoteForm } from '../../components/admin/scheme/schemeApplyNoteForm.vue'
export { default as SchemeForm } from '../../components/admin/scheme/schemeForm.vue'
export { default as SchoolForm } from '../../components/admin/school/schoolForm.vue'
export { default as Form } from '../../components/admin/schoolApplicationRecord/Form.vue'
export { default as JsonInput } from '../../components/admin/schoolApplicationRecord/JsonInput.vue'
export { default as SmsTemplateCompose } from '../../components/admin/smsTemplate/smsTemplateCompose.vue'
export { default as SmsTemplateForm } from '../../components/admin/smsTemplate/smsTemplateForm.vue'
export { default as UserForm } from '../../components/admin/user/userForm.vue'
export { default as VenueForm } from '../../components/admin/venue/venueForm.vue'
export { default as Tab5 } from '../../components/apply/spaps/tab5.vue'
export { default as NotificationCard } from '../../components/common/notification/notification_card.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
