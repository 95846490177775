var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: _vm.code, size: "xl", "hide-footer": true },
      on: { shown: _vm.shown, hide: _vm.hide },
    },
    [
      _c("email-template-form", {
        attrs: {
          action: "send",
          "is-show": _vm.isShow,
          "trn-id": _vm.trnId,
          "temp-code": _vm.code,
          custom: _vm.custom,
          "has-free-teacher-ticket": _vm.hasFreeTeacherTicket,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }