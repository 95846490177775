<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="show" class="notibox">
    <div class="notibox-heading">
      <div class="noti_title">{{ $t('noti.title') }}</div>
      <!-- ({{ count_noti() }}) -->
      <div class="close_noti" @click="closeNoti">
        <b-icon icon="x"></b-icon>
      </div>
      <div v-if="user.isAdmin" class="noti_btn_grp">
        <b-button
          v-if="checkRole() === 't3' || checkRole() === 'admin'"
          class="noti_btn"
          variant="outline-light"
          :pressed="showingScheme === 'scds'"
          @click="changeTab('scds')"
        >
          {{ 'SCDS' }} ({{
            scdsArray.filter((a) => {
              return a.staff_handled === 0
            }).length
          }})
        </b-button>
        <b-button
          v-if="checkRole() === 't2' || checkRole() === 'admin'"
          class="noti_btn"
          variant="outline-light"
          :pressed="showingScheme === 'spaps'"
          @click="changeTab('spaps')"
        >
          {{ 'SPAPS' }} ({{
            spapsArray.filter((a) => {
              return a.staff_handled === 0
            }).length
          }})
        </b-button>
        <b-button
          v-if="checkRole() === 't2' || checkRole() === 'admin'"
          class="noti_btn"
          variant="outline-light"
          :pressed="showingScheme === 'aes'"
          @click="changeTab('aes')"
        >
          {{ 'AES' }} ({{
            aesArray.filter((a) => {
              return a.staff_handled === 0
            }).length
          }})
        </b-button>
        <b-button
          v-if="checkRole() === 't2' || checkRole() === 'admin'"
          class="noti_btn"
          variant="outline-light"
          :pressed="showingScheme === 'aoa'"
          @click="changeTab('aoa')"
        >
          {{ 'AOA' }} ({{
            aoaArray.filter((a) => {
              return a.staff_handled === 0
            }).length
          }})
        </b-button>
        <b-button
          v-if="checkRole() === 't2' || checkRole() === 'admin'"
          class="noti_btn"
          variant="outline-light"
          :pressed="showingScheme === 'other'"
          @click="changeTab('other')"
        >
          {{ 'Other' }} ({{
            otherArray.filter((a) => {
              return a.staff_handled === 0
            }).length
          }})
        </b-button>
      </div>
      <div v-else>
        <hr style="margin: 10px 0px 0px 0px" />
      </div>
    </div>

    <!-- ADMIN: SCDS NOTI -->
    <div v-if="showingScheme === 'scds'" class="noti-main">
      <div v-if="scdsArray.length !== 0">
        <div v-for="(noti, index) in scdsArray.slice().reverse()" :key="index">
          <!-- NEW_APPLICATION -->
          <NotificationCard
            v-if="noti.type === 'NEW_APPLICATION'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.new_application')"
            :datetime="noti.created_at"
          >
            <span>{{ noti.title }} – </span>
            <span v-html="getEventName(noti.event_id)"></span>
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 1, 'new')"
            ></span>
            <span>{{ noti.trnId }}</span>
          </NotificationCard>

          <!-- CHANGE_REQUEST -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_REQUEST'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.staff_handled"
            :card-title="$t('noti.change_request')"
            :datetime="noti.created_at"
          >
            <span>{{ noti.title }} – </span>
            <span v-html="getEventName(noti.event_id)"></span>:
            {{ noti.schoolname }}
            <span
              v-if="isCancel(noti.content)"
              v-html="getContent(noti.admin_team, noti.trnId, 'request_cancel')"
            ></span>
            <span v-else>
              <span
                v-html="getContent(noti.admin_team, noti.trnId, 'request_edit')"
              ></span>
              {{ editSection(noti.content, noti.admin_team) }}
            </span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>

          <!-- FEEDBACK -->
          <NotificationCard
            v-if="noti.type === 'FEEDBACK'"
            :id="noti.id"
            icon="chat-left-text"
            color="rgb(123, 200, 245)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.feedback')"
            :datetime="noti.created_at"
          >
            <div>{{ noti.username }}{{ $t('noti.feedback_content') }}</div>
            <div style="color: #6a6a6a">"{{ noti.content }}"</div>
          </NotificationCard>

          <!-- CANCEL_BEFORE_DEADLINE -->
          <NotificationCard
            v-if="noti.type === 'CANCEL_APPLICATION_BEFORE_DEADLINE'"
            :id="noti.id"
            icon="exclamation-square"
            color="#e86767"
            :handled="noti.staff_handled"
            :card-title="$t('noti.cancel_application')"
            :datetime="noti.created_at"
          >
            <span>{{ noti.title }} – </span>
            <span v-html="getEventName(noti.event_id)"></span>:
            {{ noti.schoolname }}
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 1, 'cancel')"
            ></span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>
        </div>
      </div>
      <div v-else>
        <div class="no_noti">{{ $t('noti.no_noti') }}</div>
      </div>
    </div>

    <!-- ADMIN: SPAPS NOTI -->
    <div v-if="showingScheme === 'spaps'" class="noti-main">
      <div v-if="spapsArray.length !== 0">
        <div v-for="(noti, index) in spapsArray.slice().reverse()" :key="index">
          <!-- NEW_APPLICATION -->
          <NotificationCard
            v-if="noti.type === 'NEW_APPLICATION'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.new_application')"
            :datetime="noti.created_at"
          >
            <div v-if="noti.title" class="spaps-warn">
              {{ $t('noti.submission_count') }}{{ noti.title }}
            </div>
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 2, 'new')"
            ></span>
            <span>{{ noti.trnId }}</span>
          </NotificationCard>

          <!-- CHANGE_REQUEST -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_REQUEST'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.staff_handled"
            :card-title="$t('noti.change_request')"
            :datetime="noti.created_at"
          >
            {{ noti.schoolname }}
            <span
              v-if="isCancel(noti.content)"
              v-html="getContent(noti.admin_team, noti.trnId, 'request_cancel')"
            ></span>
            <span v-else>
              <span
                v-html="getContent(noti.admin_team, noti.trnId, 'request_edit')"
              ></span>
              {{ editSection(noti.content, noti.admin_team) }}
            </span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>

          <!-- FEEDBACK -->
          <NotificationCard
            v-if="noti.type === 'FEEDBACK'"
            :id="noti.id"
            icon="chat-left-text"
            color="rgb(123, 200, 245)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.feedback')"
            :datetime="noti.created_at"
          >
            <div>{{ noti.username }}{{ $t('noti.feedback_content') }}</div>
            <div style="color: #6a6a6a">"{{ noti.content }}"</div>
          </NotificationCard>

          <!-- CANCEL_BEFORE_DEADLINE -->
          <NotificationCard
            v-if="noti.type === 'CANCEL_APPLICATION_BEFORE_DEADLINE'"
            :id="noti.id"
            icon="exclamation-square"
            color="#e86767"
            :handled="noti.staff_handled"
            :card-title="$t('noti.cancel_application')"
            :datetime="noti.created_at"
          >
            {{ noti.schoolname }}
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 2, 'cancel')"
            ></span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>
        </div>
      </div>
      <div v-else>
        <div class="no_noti">{{ $t('noti.no_noti') }}</div>
      </div>
    </div>

    <!-- ADMIN: AES NOTI -->
    <div v-if="showingScheme === 'aes'" class="noti-main">
      <div v-if="aesArray.length !== 0">
        <div v-for="(noti, index) in aesArray.slice().reverse()" :key="index">
          <!-- NEW_APPLICATION -->
          <NotificationCard
            v-if="noti.type === 'NEW_APPLICATION'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.new_application')"
            :datetime="noti.created_at"
          >
            <span v-html="getEventName(noti.event_id)"></span>
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 3, 'new')"
            ></span>
            <span>{{ noti.trnId }}</span>
          </NotificationCard>

          <!-- CHANGE_REQUEST -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_REQUEST'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.staff_handled"
            :card-title="$t('noti.change_request')"
            :datetime="noti.created_at"
          >
            <span v-html="getEventName(noti.event_id)"></span>:
            {{ noti.schoolname }}
            <span
              v-if="isCancel(noti.content)"
              v-html="getContent(noti.admin_team, noti.trnId, 'request_cancel')"
            ></span>
            <span v-else>
              <span
                v-html="getContent(noti.admin_team, noti.trnId, 'request_edit')"
              ></span>
              {{ editSection(noti.content, noti.admin_team) }}
            </span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>

          <!-- FEEDBACK -->
          <NotificationCard
            v-if="noti.type === 'FEEDBACK'"
            :id="noti.id"
            icon="chat-left-text"
            color="rgb(123, 200, 245)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.feedback')"
            :datetime="noti.created_at"
          >
            <div>{{ noti.username }}{{ $t('noti.feedback_content') }}</div>
            <div style="color: #6a6a6a">"{{ noti.content }}"</div>
          </NotificationCard>

          <!-- CANCEL_BEFORE_DEADLINE -->
          <NotificationCard
            v-if="noti.type === 'CANCEL_APPLICATION_BEFORE_DEADLINE'"
            :id="noti.id"
            icon="exclamation-square"
            color="#e86767"
            :handled="noti.staff_handled"
            :card-title="$t('noti.cancel_application')"
            :datetime="noti.created_at"
          >
            <span v-html="getEventName(noti.event_id)"></span>:
            {{ noti.schoolname }}
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 3, 'cancel')"
            ></span>
            <span>({{ noti.trnId }})</span>
          </NotificationCard>
        </div>
      </div>
      <div v-else>
        <div class="no_noti">{{ $t('noti.no_noti') }}</div>
      </div>
    </div>

    <!-- ADMIN: AOA NOTI -->
    <div v-if="showingScheme === 'aoa'" class="noti-main">
      <div v-if="aoaArray.length !== 0">
        <div v-for="(noti, index) in aoaArray.slice().reverse()" :key="index">
          <!-- NEW_APPLICATION -->
          <NotificationCard
            v-if="noti.type === 'NEW_APPLICATION'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.new_application')"
            :datetime="noti.created_at"
          >
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 4, 'new')"
            ></span>
            <span>{{ noti.trnId }}</span>
          </NotificationCard>

          <!-- FEEDBACK -->
          <NotificationCard
            v-if="noti.type === 'FEEDBACK'"
            :id="noti.id"
            icon="chat-left-text"
            color="rgb(123, 200, 245)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.feedback')"
            :datetime="noti.created_at"
          >
            <div>{{ noti.username }}{{ $t('noti.feedback_content') }}</div>
            <div style="color: #6a6a6a">"{{ noti.content }}"</div>
          </NotificationCard>
        </div>
      </div>
    </div>

    <!-- ADMIN: OTHER NOTI -->
    <div v-if="showingScheme === 'other'" class="noti-main">
      <div v-if="otherArray.length !== 0">
        <div v-for="(noti, index) in otherArray.slice().reverse()" :key="index">
          <!-- NEW_APPLICATION -->
          <NotificationCard
            v-if="noti.type === 'NEW_APPLICATION'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.new_application')"
            :datetime="noti.created_at"
          >
            <span
              v-html="getLink(noti.event_id, noti.schemeId, 4, 'new')"
            ></span>
            <span>{{ noti.trnId }}</span>
          </NotificationCard>

          <!-- FEEDBACK -->
          <NotificationCard
            v-if="noti.type === 'FEEDBACK'"
            :id="noti.id"
            icon="chat-left-text"
            color="rgb(123, 200, 245)"
            :handled="noti.staff_handled"
            :card-title="$t('noti.feedback')"
            :datetime="noti.created_at"
          >
            <div>{{ noti.username }}{{ $t('noti.feedback_content') }}</div>
            <div style="color: #6a6a6a">"{{ noti.content }}"</div>
          </NotificationCard>
        </div>
      </div>
    </div>

    <!-- TEACHER ACCOUNT -->
    <div v-if="!checkRole()" class="noti-main-teacher">
      <div v-if="notiArray.length !== 0">
        <div v-for="(noti, index) in notiArray.slice().reverse()" :key="index">
          <!-- UPDATE_APPLICATION_RESULT -->
          <NotificationCard
            v-if="noti.type === 'UPDATE_APPLICATION_RESULT'"
            :id="noti.id"
            icon="file-earmark-text"
            color="rgb(131, 160, 52)"
            :handled="noti.user_handled"
            :card-title="$t('noti.update_application_result')"
            :datetime="noti.created_at"
          >
            <span>
              {{ $t('noti.update_application_result_content1') }}({{
                noti.trnId
              }})
            </span>
            <span
              v-html="getContent(noti.admin_team, noti.trnId, 'update_result')"
            ></span>
          </NotificationCard>

          <!-- EVENT_CANCELLATION -->
          <NotificationCard
            v-if="noti.type === 'EVENT_CANCELLATION'"
            :id="noti.id"
            icon="fexclamation-square"
            color="#e86767"
            :handled="noti.user_handled"
            :card-title="$t('noti.event_cancellation')"
            :datetime="noti.created_at"
          >
            <span>{{ noti.content }}</span>
          </NotificationCard>

          <!-- CHANGE_APPLICATION_DETAIL -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_APPLICATION_DETAIL'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.user_handled"
            :card-title="$t('noti.change_application_detail')"
            :datetime="noti.created_at"
          >
            <span>{{ noti.content }}</span>
          </NotificationCard>

          <!-- PROMOTION -->
          <NotificationCard
            v-if="noti.type === 'PROMOTION'"
            :id="noti.id"
            icon="volume-up"
            color="#e2a9f2"
            :handled="noti.user_handled"
            :card-title="changeLang('subject', noti.title)"
            :datetime="noti.created_at"
          >
            <!-- <div v-if="noti.title">{{ changeLang('subject', noti.title) }}</div> -->
            <div v-if="noti.content">
              {{ changeLang('content', noti.content) }}
            </div>
          </NotificationCard>

          <!-- CHANGE_REQUEST_APPROVED -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_REQUEST_APPROVED'"
            :id="noti.id"
            icon="volume-up"
            color="#dea95f"
            :handled="noti.user_handled"
            :card-title="$t('noti.change_request')"
            :datetime="noti.created_at"
          >
            <div v-if="noti.admin_team === 'spaps'">
              {{ $t('sar.spaps') }}：
            </div>
            <div v-else-if="noti.admin_team === 'scds'">
              {{ noti.title }}
              <span v-html="getEventName(noti.event_id)"></span>：
            </div>
            <div v-else-if="noti.admin_team === 'aes'">
              <span v-html="getEventName(noti.event_id)"></span>：
            </div>
            <div
              v-if="noti.content.includes('cancel')"
              v-html="
                getContent(noti.admin_team, noti.trnId, 'cancel_approved')
              "
            ></div>
            <div v-else>
              <span
                v-html="
                  getContent(noti.admin_team, noti.trnId, 'change_approved')
                "
              ></span>
            </div>
          </NotificationCard>

          <!-- CHANGE_REQUEST_REJECTED -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_REQUEST_REJECTED'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.user_handled"
            :card-title="$t('noti.change_request')"
            :datetime="noti.created_at"
          >
            <div v-if="noti.admin_team === 'spaps'">
              {{ $t('sar.spaps') }}：
            </div>
            <div v-else-if="noti.admin_team === 'scds'">
              {{ noti.title }}
              <span v-html="getEventName(noti.event_id)"></span>：
            </div>
            <div v-else-if="noti.admin_team === 'aes'">
              <span v-html="getEventName(noti.event_id)"></span>：
            </div>
            <div
              v-html="
                getContent(noti.admin_team, noti.trnId, 'change_rejected')
              "
            ></div>
          </NotificationCard>

          <!-- CHANGE_EVENT_DETAIL -->
          <NotificationCard
            v-if="noti.type === 'CHANGE_EVENT_DETAIL'"
            :id="noti.id"
            icon="pencil-square"
            color="#dea95f"
            :handled="noti.user_handled"
            :card-title="$t('noti.change_event_detail')"
            :datetime="noti.created_at"
          >
            <span>
              <a :href="getEventLink(noti.content)">
                {{ getEventTitle(noti.content) }}
              </a>
            </span>
            <span>{{ $t('noti.change_event_detail_content') }}</span>
          </NotificationCard>
        </div>
      </div>
      <div v-else>
        <div class="no_noti">{{ $t('noti.no_noti') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationCard from './notification/notification_card.vue'
export default {
  component: { NotificationCard },
  name: 'NotiBox',
  props: {
    user: {
      type: Object,
      default() {
        return { role: '' }
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(`users`)
      this.users = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(`schools`)
      this.schools = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/event`
      )
      this.events = result.data.data
      // console.log('this.events: ', this.events)
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/getList`
      )
      // console.log('this result:', result.data.data)
      this.schemeList = result.data.data
      // console.log('this.schemeList:', this.schemeList)
    } catch (err) {
      console.log(err)
    }
  },
  data() {
    return {
      showingScheme: '',
      notiArray: [],
      scdsArray: [],
      spapsArray: [],
      aesArray: [],
      aoaArray: [],
      otherArray: [],
      notiSet: {},
      timer: '',
    }
  },
  watch: {
    show: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  created() {
    this.fetchNotiList()
    this.timer = setInterval(this.fetchNotiList, 60000)
  },
  mounted() {
    this.setTab()
  },
  methods: {
    getEventLink(content) {
      console.log(content)
      const { event } = JSON.parse(content)
      if (!event) return ''
      if (window.location.href.includes('artconnect')) {
        return `/artconnect/${this.$i18n.locale}/event/${event.id}`
      } else {
        return `/${this.$i18n.locale}/event/${event.id}`
      }
    },
    getEventTitle(content) {
      const { event } = JSON.parse(content)
      if (event) return event.code + '-' + event['title_' + this.$i18n.locale]
      else return ''
    },
    async fetchNotiList() {
      try {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}notification`
        )
        this.tmp_notiArray = result.data.data
        // complete noti list
        this.notiArray = this.tmp_notiArray
        // .filter((t) => {
        //   return t.staff_handled === 0
        //   // && t.user_handled === 0
        // })
        this.notiArray.forEach((data) => {
          data.created_at = this.$moment(data.created_at).format(
            'D.M.YYYY HH:mm'
          )
          data.username = this.getUser(data.user_id)
          data.schoolname = this.getSchoolName(this.getSchoolId(data.user_id))
        })
        this.scdsArray = []
        this.spapsArray = []
        this.aesArray = []
        this.aoaArray = []
        this.otherArray = []
        this.notiArray.map((n) => {
          // if(this.notiSet[n.id] != null) {
          //   return;
          // }
          // this.notiSet[n.id] = true;
          if (n.admin_team === 'scds') {
            // scds noti list only
            this.scdsArray.push(n)
          } else if (n.admin_team === 'spaps') {
            // spaps noti list only
            this.spapsArray.push(n)
          } else if (n.admin_team === 'aes') {
            // aes noti list only
            this.aesArray.push(n)
          } else if (n.admin_team === 'aoa') {
            // aoa noti list only
            this.aoaArray.push(n)
          } else {
            // other noti list only
            this.otherArray.push(n)
          }
        })
      } catch (err) {
        console.error(err)
      }
    },
    // mark noti as read
    async submit(nid) {
      try {
        await this.$axios.$post(`${process.env.cloudApiUrl}notification/`, {
          id: nid,
        })
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
      this.fetchNotiList()
      console.log('submitted')
    },
    async setTab() {
      await this.$axios.get(`users/profile`)
      // console.log(result.data.data[0].role)
      if (this.checkRole() === 'admin' || this.checkRole() === 't3') {
        this.showingScheme = 'scds'
        // this.getNoti()
      } else if (this.checkRole() === 't2') {
        this.showingScheme = 'spaps'
        // this.getNoti()
      }
    },
    checkRole() {
      const admin = [1, 2, 11, 12]
      const t2 = [4, 7, 13]
      const t3 = [5, 8, 9, 10]
      // console.log('this.user: ', this.user)
      if (admin.includes(this.user.role)) {
        return 'admin'
      } else if (t2.includes(this.user.role)) {
        return 't2'
      } else if (t3.includes(this.user.role)) {
        return 't3'
      } else {
        // console.log(this.user.isAdmin)
        return this.user.isAdmin
      }
    },
    changeTab(scheme) {
      this.showingScheme = scheme
    },
    getUser(userid) {
      // console.log('userid: ', userid)
      const id = userid
      // console.log(this.users)
      if (this.users) {
        return this.users.find((c) => c.id === id)
          ? this.users.find((c) => c.id === id).name
          : id
      }
    },
    getSchoolId(userid) {
      // console.log('userid: ', userid)
      const id = userid
      // console.log(this.users)
      if (this.users) {
        return this.users.find((c) => c.id === id)
          ? this.users.find((c) => c.id === id).school
          : ''
      }
    },
    getSchoolName(schoolid) {
      // console.log('userid: ', userid)
      const id = schoolid
      // console.log(this.users)
      if (this.schools) {
        return this.schools.find((c) => c.id === id)
          ? this.schools.find((c) => c.id === id).name_tc
          : ''
      }
    },
    changeLang(type, text) {
      try {
        const formattedText = JSON.parse(text)
        return formattedText[`${type}_${this.$i18n.locale}`]
      } catch {
        return text
      }
    },
    getEventName(eid) {
      if (this.events) {
        const event = this.events.find((e) => e.id === eid)
        return event ? this.i18nTitle(event) : ''
      }
    },
    getContent(schemeType, trnId, type) {
      if (window.location.href.includes('artconnect')) {
        if (type === 'request_edit') {
          return `<a href="${this.$t(
            'noti.change_request_link_prod'
          )}?keyword=${trnId}&schemeType=${schemeType.toUpperCase()}">${this.$t(
            'noti.change_request_edit'
          )}</a>：`
        } else if (type === 'request_cancel') {
          return `<a href="${this.$t(
            'noti.change_request_link_prod'
          )}?keyword=${trnId}&schemeType=${schemeType.toUpperCase()}">${this.$t(
            'noti.change_request_cancel'
          )}</a>：`
        } else if (type === 'update_result') {
          return this.$t('noti.update_application_result_content2_prod')
        } else if (type === 'change_approved') {
          return this.$t('noti.change_request_approved_content_prod')
        } else if (type === 'cancel_approved') {
          return this.$t('noti.cancel_request_approved_content_prod')
        } else if (type === 'change_rejected') {
          return this.$t('noti.change_request_rejected_prod')
        }
      } else if (type === 'request_edit') {
        return `<a href="${this.$t(
          'noti.change_request_link'
        )}?keyword=${trnId}&schemeType=${schemeType.toUpperCase()}">${this.$t(
          'noti.change_request_edit'
        )}</a>：`
      } else if (type === 'request_cancel') {
        return `<a href="${this.$t(
          'noti.change_request_link'
        )}?keyword=${trnId}&schemeType=${schemeType.toUpperCase()}">${this.$t(
          'noti.change_request_cancel'
        )}</a>：`
      } else if (type === 'update_result') {
        return this.$t('noti.update_application_result_content2')
      } else if (type === 'change_approved') {
        return this.$t('noti.change_request_approved_content')
      } else if (type === 'cancel_approved') {
        return this.$t('noti.cancel_request_approved_content')
      } else if (type === 'change_rejected') {
        return this.$t('noti.change_request_rejected')
      }
    },
    getLink(eid, sid, scheme, type) {
      let link = ''
      let schemeId = 0
      let schemeSlug = ''
      let content = ''
      if (scheme === 1) {
        link = `${process.env.base?.slice(0, -1)}${this.localePath(
          '/admin/application/scds'
        )}`
      } else if (scheme === 2) {
        link = `${process.env.base?.slice(0, -1)}${this.localePath(
          '/admin/application/spaps'
        )}`
      } else if (scheme === 3) {
        link = `${process.env.base?.slice(0, -1)}${this.localePath(
          '/admin/application/aes'
        )}`
      } else if (scheme === 4) {
        link = `${process.env.base?.slice(0, -1)}${this.localePath(
          '/admin/application/aoa'
        )}`
      }

      if (eid) {
        // scds & aes
        // Find scheme Id
        if (this.events) {
          const event = this.events.find((e) => e.id === eid)
          schemeId = event ? event.scheme : ''
        }
        // Find scheme slug
        const scheme = this.schemeList.find((s) => s.id === schemeId)
        schemeSlug = scheme ? scheme.slug : ''
      } else {
        // spaps
        // Find scheme slug
        const scheme = this.schemeList.find((s) => s.id === sid)
        schemeSlug = scheme ? scheme.slug : ''
      }

      if (scheme === 2) {
        // SPAPS (no event Id, only scheme slug)
        link = link + '/' + schemeSlug + '/all'
      } else if (scheme === 4) {
        // AOA： '/tc/admin/application/aoa/all'
        link = link + '/' + 'all'
      } else {
        // SCDS & AES (have scheme slug & event Id)
        link = link + '/' + schemeSlug + '/all?eventid=' + eid
      }

      if (type === 'new') {
        content = this.$t('noti.new_application_content')
      } else if (type === 'cancel') {
        content = this.$t('noti.cancel_application_content')
      }

      return '<a href="' + link + '">' + content + '</a>'
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    count_noti() {
      if (this.checkRole() === 't3') {
        return this.scdsArray.length
      } else if (this.checkRole() === 't2') {
        return (
          this.spapsArray.length + this.aesArray.length + this.aoaArray.length
        )
      } else if (this.checkRole() === 'admin') {
        return (
          this.scdsArray.length +
          this.spapsArray.length +
          this.aesArray.length +
          this.aoaArray.length
        )
      } else {
        return this.notiArray.length
      }
    },
    isCancel(content) {
      content = JSON.parse(content)
      return content.changeSection.includes('cancel')
    },
    editSection(section, team) {
      if (this.user.isAdmin) {
        section = JSON.parse(section).changeSection
      }
      // console.log(section)
      let secArr = ''
      // if (section.includes('eventslot')) {
      //   secArr += this.$t('change_request.section.eventslot') + ', '
      // }
      if (section.includes('class')) {
        secArr += this.$t('change_request.section.class') + '/ '
      }
      if (team === 'spaps' && section.includes('number')) {
        secArr += this.$t('change_request.section.anti_number') + '/ '
      }
      if (section.includes('number')) {
        if (section.includes('studentNo')) {
          secArr += this.$t('change_request.section.studentNo') + '/ '
        }
        if (team === 'scds' && section.includes('parentNo')) {
          secArr += this.$t('change_request.section.parentNo_short') + '/ '
        } else if (team === 'aes' && section.includes('parentNo')) {
          secArr += this.$t('change_request.section.cssano_short') + '/ '
        }
        if (section.includes('teacherNo')) {
          secArr += this.$t('change_request.section.teacherNo') + '/ '
        }
      }
      if (section.includes('add_teacher')) {
        secArr += this.$t('change_request.section.add_teacher') + '/ '
      }
      if (section.includes('add_lead_teacher')) {
        secArr += this.$t('change_request.section.add_lead_teacher') + '/ '
      }
      if (section.includes('other')) {
        secArr += this.$t('change_request.section.other_short') + '/ '
      }
      if (section.includes('cancel')) {
        secArr += this.$t('change_request.section.cancel') + '/ '
      }
      if (section.includes('noEdit')) {
        secArr += this.$t('change_request.section.noEdit') + '/ '
      }
      return secArr.slice(0, -2)
    },
    closeNoti() {
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.notibox {
  position: absolute;
  width: 360px;
  height: 450px;
  right: 2px;
  top: 75px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0px 20px 20px 20px;
  overflow-y: scroll;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.notibox-heading {
  position: sticky;
  top: 0;
  background: white;
  width: 325px;
  padding-top: 15px;
  z-index: 1;
}

.noti_title {
  color: #1cbcb4;
  font-size: 130%;
  text-align: left;
  font-weight: 500;
}

.noti_btn_grp {
  height: auto;
  width: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  border-bottom: 1.5px solid #e3e3e3;
}

.noti_btn {
  min-width: 33.3%;
  line-height: 1;
  border-radius: 10px 10px 0px 0px;
  background-color: whitesmoke !important;
  color: #6a6a6a !important;
  border: 1.5px solid #e3e3e3 !important;
}

.noti_btn:hover {
  color: #1cbcb4 !important;
}

.noti_btn.active {
  background-color: white !important;
  color: #1cbcb4 !important;
}

.noti-main {
  position: relative;
}

.noti-main-teacher {
  padding-top: 0px;
}

.close_noti {
  display: none;
}

.no_noti {
  padding: 10px 0px;
}

.spaps-warn {
  color: red;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .close_noti {
    cursor: pointer;
    float: right;
    position: relative;
    right: 15px;
    top: -30px;
    font-size: 120%;
    display: block;
  }

  .close_noti:hover {
    color: #7e7e7e;
  }
}
</style>
