var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "10" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t("event.otherInfo")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  id: "title",
                                  description: "「已取消」活動不會開放報名",
                                  label: _vm.$t("event.status"),
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    type: "number",
                                    options: _vm.statusList,
                                    required: "",
                                    trim: "",
                                    placeholder: _vm.$t("event.status"),
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  id: "title",
                                  "label-for": "title",
                                  description:
                                    "填寫後將不會顯示自動產生的「已完成」/「已取消」",
                                },
                              },
                              [
                                _c(
                                  "b-tabs",
                                  [
                                    _c(
                                      "b-tab",
                                      { attrs: { title: "自訂活動狀態(英)" } },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.statusTxt.content_en,
                                            options: _vm.options_statusTxt_en,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.statusTxt.content_en =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: "自訂活動狀態(繁)" } },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.statusTxt.content_tc,
                                            options: _vm.options_statusTxt_tc,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.statusTxt.content_tc =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: "自訂活動狀態(簡)" } },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.statusTxt.content_sc,
                                            options: _vm.options_statusTxt_sc,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.statusTxt.content_sc =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "p",
                              {
                                staticClass: "label",
                                staticStyle: { color: "red" },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("event.adminRemark")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-form-textarea", {
                              attrs: {
                                id: "adminRemark",
                                rows: "6",
                                "max-rows": "6",
                              },
                              model: {
                                value: _vm.adminRemark,
                                callback: function ($$v) {
                                  _vm.adminRemark = $$v
                                },
                                expression: "adminRemark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { id: "scheme", "label-for": "scheme" },
                              },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(_vm._s(_vm.$t("event.scheme"))),
                                ]),
                                _vm._v(" "),
                                _c("b-form-select", {
                                  attrs: { options: _vm.schemeList },
                                  model: {
                                    value: _vm.scheme,
                                    callback: function ($$v) {
                                      _vm.scheme = $$v
                                    },
                                    expression: "scheme",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.getParentSchemeId(_vm.scheme) === 1 ||
                        _vm.haveCat(_vm.scheme)
                          ? _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "category_id",
                                      "label-for": "category_id",
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("event.category_id"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getCategoryList(
                                          _vm.scheme
                                        ),
                                      },
                                      model: {
                                        value: _vm.category_id,
                                        callback: function ($$v) {
                                          _vm.category_id = $$v
                                        },
                                        expression: "category_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "tag", "label-for": "tag" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(_vm._s(_vm.$t("event.tag"))),
                                ]),
                                _vm._v(" "),
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.tags,
                                    placeholder: _vm.$t("event.tag"),
                                  },
                                  model: {
                                    value: _vm.tag,
                                    callback: function ($$v) {
                                      _vm.tag = $$v
                                    },
                                    expression: "tag",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "contact",
                                  "label-for": "contact",
                                },
                              },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("event.contact")) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.contact_id, function (c, i) {
                                  return _c("div", { key: i }, [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "90%" } },
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                options: _vm.contactList,
                                                placeholder:
                                                  _vm.$t("event.contact"),
                                              },
                                              model: {
                                                value: _vm.contact_id[i],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contact_id,
                                                    i,
                                                    $$v
                                                  )
                                                },
                                                expression: "contact_id[i]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  variant: "outline-danger",
                                                },
                                                on: {
                                                  click: () => {
                                                    _vm.contact_id.splice(i, 1)
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash-fill" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-success" },
                                    on: {
                                      click: () => {
                                        _vm.contact_id.push(-1)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  新增聯絡人\n                "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { id: "parent", "label-for": "parent" },
                              },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(_vm._s(_vm.$t("event.parent"))),
                                ]),
                                _vm._v(" "),
                                _c("b-form-select", {
                                  attrs: { options: _vm.eventList },
                                  model: {
                                    value: _vm.parent,
                                    callback: function ($$v) {
                                      _vm.parent = $$v
                                    },
                                    expression: "parent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("p", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.thumbnail"))
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.suggested_size")) +
                                  ": (720px(W) x 540px(H))\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.thumbnail,
                                callback: function ($$v) {
                                  _vm.thumbnail = $$v
                                },
                                expression: "thumbnail",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-form-file", {
                              attrs: {
                                id: "thumbnail",
                                accept: "image/jpeg, image/png, image/gif",
                              },
                              on: {
                                change: (e) =>
                                  _vm.uploadImageResize(e, false, -1, 720),
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { "max-height": "200px" },
                              attrs: {
                                src: _vm.thumbnail,
                                alt: "event thumbnail",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c("p", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.cal_img"))
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.suggested_size")) +
                                  ": (1000px(W) x 520px(H))\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.imgForCalendar,
                                callback: function ($$v) {
                                  _vm.imgForCalendar = $$v
                                },
                                expression: "imgForCalendar",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-form-file", {
                              attrs: {
                                id: "imgForCalendar",
                                accept: "image/jpeg, image/png, image/gif",
                              },
                              on: { change: _vm.uploadImage },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { "max-height": "200px" },
                              attrs: {
                                src: _vm.imgForCalendar,
                                alt: "event calendar",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticStyle: { "margin-top": "16px" } },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "startdate",
                                  "label-for": "startdate",
                                },
                              },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("event.startdate")) +
                                      "（如沒有活動場次）\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "date", trim: "" },
                                  model: {
                                    value: _vm.startdate,
                                    callback: function ($$v) {
                                      _vm.startdate = $$v
                                    },
                                    expression: "startdate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "enddate",
                                  "label-for": "enddate",
                                },
                              },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("event.enddate")) +
                                      "（如沒有活動場次）\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "date", trim: "" },
                                  model: {
                                    value: _vm.enddate,
                                    callback: function ($$v) {
                                      _vm.enddate = $$v
                                    },
                                    expression: "enddate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c("p", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.target.title")) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-form-checkbox-group", {
                              attrs: { options: _vm.target_skl_options },
                              model: {
                                value: _vm.target,
                                callback: function ($$v) {
                                  _vm.target = $$v
                                },
                                expression: "target",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("event.basicInfo")))]),
                _vm._v(" "),
                _c("div", { staticClass: "row shadow" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-7" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "2" } }, [
                            _c("p", { staticClass: "label", staticStyle: {} }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("event.code")) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { md: "5" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { id: "code", "label-for": "code" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text", trim: "" },
                                    model: {
                                      value: _vm.code,
                                      callback: function ($$v) {
                                        _vm.code = $$v
                                      },
                                      expression: "code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: { id: "isFamily" },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        value: "1",
                                        "unchecked-value": "0",
                                      },
                                      model: {
                                        value: _vm.isFamily,
                                        callback: function ($$v) {
                                          _vm.isFamily = $$v
                                        },
                                        expression: "isFamily",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "https://www.abo.gov.hk/archive/img/SCDS2021/parent.jpg",
                                          alt: _vm.$t("event.isFamily"),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { id: "isStar" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        value: "1",
                                        "unchecked-value": "0",
                                      },
                                      model: {
                                        value: _vm.isStar,
                                        callback: function ($$v) {
                                          _vm.isStar = $$v
                                        },
                                        expression: "isStar",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "https://www.abo.gov.hk/archive/img/SCDS2021/star.jpg",
                                          alt: _vm.$t("event.isStar"),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "title", "label-for": "title" } },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.eventName.en"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.title_en,
                                      options: _vm.options_title_en,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.title_en =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.eventName.tc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.title_tc,
                                      options: _vm.options_title_tc,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.title_tc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.eventName.sc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.title_sc,
                                      options: _vm.options_title_sc,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.title_sc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "title", "label-for": "title" } },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.organiser.en"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text", trim: "" },
                                    model: {
                                      value: _vm.organiser.title_en,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.organiser, "title_en", $$v)
                                      },
                                      expression: "organiser.title_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.organiser.tc"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text", trim: "" },
                                    model: {
                                      value: _vm.organiser.title_tc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.organiser, "title_tc", $$v)
                                      },
                                      expression: "organiser.title_tc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.organiser.sc"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text", trim: "" },
                                    model: {
                                      value: _vm.organiser.title_sc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.organiser, "title_sc", $$v)
                                      },
                                      expression: "organiser.title_sc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "banner",
                            label: _vm.$t("event.banner"),
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("scheme.banner_size")) +
                              ": 1000px(W) x 200px(H)\n              "
                          ),
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.banner,
                              callback: function ($$v) {
                                _vm.banner = $$v
                              },
                              expression: "banner",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: { id: "banner", accept: "image/*" },
                            on: { change: _vm.uploadImage },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { "max-height": "200px" },
                            attrs: { src: _vm.banner, alt: "banner" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "topRemark", "label-for": "title" } },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.topRemark.en"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor-topRemark",
                                    attrs: {
                                      text: _vm.topRemark.content_en,
                                      options: _vm.options_disable_return,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.topRemark.content_en =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.topRemark.tc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor-topRemark",
                                    attrs: {
                                      text: _vm.topRemark.content_tc,
                                      options: _vm.options_disable_return,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.topRemark.content_tc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.topRemark.sc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor-topRemark",
                                    attrs: {
                                      text: _vm.topRemark.content_sc,
                                      options: _vm.options_disable_return,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.topRemark.content_sc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "content", "label-for": "content" } },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.en") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_en",
                                      value: _vm.content_en,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.tc") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_tc",
                                      value: _vm.content_tc,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.sc") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_sc",
                                      value: _vm.content_sc,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: { id: "content_remark", "label-for": "title" },
                        },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.content_remark.en"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.content_remark.content_en,
                                      options: _vm.options,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.content_remark.content_en =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.content_remark.tc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.content_remark.content_tc,
                                      options: _vm.options,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.content_remark.content_tc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.$t("event.content_remark.sc"),
                                  },
                                },
                                [
                                  _c("medium-editor", {
                                    staticClass: "medium-editor",
                                    attrs: {
                                      text: _vm.content_remark.content_sc,
                                      options: _vm.options,
                                    },
                                    on: {
                                      edit: (operation) => {
                                        _vm.content_remark.content_sc =
                                          operation.api.origElements.innerHTML
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _vm._l(_vm.noOfImg, function (i, index) {
                        return _c(
                          "b-form-group",
                          {
                            key: index,
                            attrs: {
                              "label-for": "img",
                              label: _vm.$t("event.img").concat(index + 1),
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.img[index].img,
                                callback: function ($$v) {
                                  _vm.$set(_vm.img[index], "img", $$v)
                                },
                                expression: "img[index].img",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-form-file", {
                              attrs: {
                                id: `img${index}`,
                                accept: "image/jpeg, image/png, image/gif",
                              },
                              on: {
                                change: (e) => _vm.uploadImage(e, true, index),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "imgCaption",
                                  "label-for": "title",
                                },
                              },
                              [
                                _c(
                                  "b-tabs",
                                  { attrs: { "content-class": "mt-3" } },
                                  [
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("event.imgCaption.en"),
                                        },
                                      },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.img[index].caption
                                              .content_en,
                                            options: _vm.options,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.img[
                                                index
                                              ].caption.content_en =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("event.imgCaption.tc"),
                                        },
                                      },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.img[index].caption
                                              .content_tc,
                                            options: _vm.options,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.img[
                                                index
                                              ].caption.content_tc =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("event.imgCaption.sc"),
                                        },
                                      },
                                      [
                                        _c("medium-editor", {
                                          staticClass: "medium-editor",
                                          attrs: {
                                            text: _vm.img[index].caption
                                              .content_sc,
                                            options: _vm.options,
                                          },
                                          on: {
                                            edit: (operation) => {
                                              _vm.img[
                                                index
                                              ].caption.content_sc =
                                                operation.api.origElements.innerHTML
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { "max-height": "200px" },
                              attrs: { src: _vm.img[index].img, alt: "image" },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn_img_del",
                                attrs: { variant: "outline-danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delImg(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("event.del_img")) +
                                    _vm._s(index + 1) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-success" },
                          on: { click: _vm.addImg },
                        },
                        [_vm._v(_vm._s(_vm.$t("event.add_img")))]
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm.getParentSchemeId(_vm.scheme) !== 1
                  ? _c("div", [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("event.tabContent")))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "shadow mt-3 mb-10",
                          staticStyle: { padding: "30px" },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: { "align-item": "middle" },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2 mb-3",
                                        attrs: { variant: "outline-success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addContentTab(
                                              0,
                                              _vm.addTabIndex
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    添加分段內容\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        type: "number",
                                        min: 1,
                                        max: _vm.tabs.length + 1,
                                        placeholder: "位置(選填)",
                                      },
                                      model: {
                                        value: _vm.addTabIndex,
                                        callback: function ($$v) {
                                          _vm.addTabIndex = $$v
                                        },
                                        expression: "addTabIndex",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticStyle: { float: "right" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addContentTab(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    添加 SPAPS 範本\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addContentTab(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    添加 AES 範本\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addContentTab(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    添加 AOA 範本\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tabs",
                            { attrs: { "content-class": "mt-3" } },
                            _vm._l(_vm.tabs, function (t, counter) {
                              return _c(
                                "b-tab",
                                {
                                  key: counter,
                                  staticClass: "tab",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(counter + 1) +
                                                ") " +
                                                _vm._s(_vm.i18nTitle(t)) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                staticStyle: {
                                                  "border-radius": "500%",
                                                },
                                                attrs: {
                                                  size: "sm",
                                                  variant: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteContentTab(
                                                      counter
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    ×\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "tabs",
                                        "label-for": "tabs",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-tabs",
                                        {
                                          attrs: {
                                            "content-class": "tab-column",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-tab",
                                            {
                                              attrs: { title: _vm.$t("g.en") },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("tab.title.en")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.title.en"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value: t.title_en,
                                                  callback: function ($$v) {
                                                    _vm.$set(t, "title_en", $$v)
                                                  },
                                                  expression: "t.title_en",
                                                },
                                              }),
                                              _vm._v(" "),
                                              t.aoaContent
                                                ? _c("AoaContent", {
                                                    attrs: {
                                                      "pass-data": t.aoaContent,
                                                      type: t.aoaType,
                                                      lang: 0,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.aoaContent,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "aoaContent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.aoaContent",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_detail
                                                ? _c("SpapsTableDetail", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_detail,
                                                      lang: 0,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_detail,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_detail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_detail",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_en ||
                                              t.content_en === ""
                                                ? _c(
                                                    "p",
                                                    { staticClass: "label" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "tab.content.en"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_en ||
                                              t.content_en === ""
                                                ? _c("tinymceEditor", {
                                                    attrs: {
                                                      id:
                                                        "tab_editor_en_" +
                                                        counter,
                                                      value: t.content_en,
                                                    },
                                                    on: {
                                                      writeContent:
                                                        _vm.getTabContent,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_impl
                                                ? _c("SpapsTableImpl", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_impl,
                                                      lang: 0,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_impl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_impl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_impl",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-tab",
                                            {
                                              attrs: { title: _vm.$t("g.tc") },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("tab.title.tc")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.title.tc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value: t.title_tc,
                                                  callback: function ($$v) {
                                                    _vm.$set(t, "title_tc", $$v)
                                                  },
                                                  expression: "t.title_tc",
                                                },
                                              }),
                                              _vm._v(" "),
                                              t.aoaContent
                                                ? _c("AoaContent", {
                                                    attrs: {
                                                      "pass-data": t.aoaContent,
                                                      type: t.aoaType,
                                                      lang: 1,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.aoaContent,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "aoaContent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.aoaContent",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_detail
                                                ? _c("SpapsTableDetail", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_detail,
                                                      lang: 1,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_detail,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_detail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_detail",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_tc ||
                                              t.content_tc === ""
                                                ? _c(
                                                    "p",
                                                    { staticClass: "label" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "tab.content.tc"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_tc ||
                                              t.content_tc === ""
                                                ? _c("tinymceEditor", {
                                                    attrs: {
                                                      id:
                                                        "tab_editor_tc_" +
                                                        counter,
                                                      value: t.content_tc,
                                                    },
                                                    on: {
                                                      writeContent:
                                                        _vm.getTabContent,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_impl
                                                ? _c("SpapsTableImpl", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_impl,
                                                      lang: 1,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_impl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_impl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_impl",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-tab",
                                            {
                                              attrs: { title: _vm.$t("g.sc") },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("tab.title.sc")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.title.sc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value: t.title_sc,
                                                  callback: function ($$v) {
                                                    _vm.$set(t, "title_sc", $$v)
                                                  },
                                                  expression: "t.title_sc",
                                                },
                                              }),
                                              _vm._v(" "),
                                              t.aoaContent
                                                ? _c("AoaContent", {
                                                    attrs: {
                                                      "pass-data": t.aoaContent,
                                                      type: t.aoaType,
                                                      lang: 2,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.aoaContent,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "aoaContent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.aoaContent",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_detail
                                                ? _c("SpapsTableDetail", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_detail,
                                                      lang: 2,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_detail,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_detail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_detail",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_sc ||
                                              t.content_sc === ""
                                                ? _c(
                                                    "p",
                                                    { staticClass: "label" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "tab.content.sc"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.content_sc ||
                                              t.content_sc === ""
                                                ? _c("tinymceEditor", {
                                                    attrs: {
                                                      id:
                                                        "tab_editor_sc_" +
                                                        counter,
                                                      value: t.content_sc,
                                                    },
                                                    on: {
                                                      writeContent:
                                                        _vm.getTabContent,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              t.table_impl
                                                ? _c("SpapsTableImpl", {
                                                    attrs: {
                                                      "impl-table-data":
                                                        t.table_impl,
                                                      lang: 2,
                                                      edit: true,
                                                    },
                                                    model: {
                                                      value: t.table_impl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          t,
                                                          "table_impl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "t.table_impl",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("event.sessInfo")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("event-table", {
                      attrs: {
                        event: _vm.getEvent,
                        remark: _vm.remark,
                        additional: _vm.additional_field,
                        "is-edit": "",
                      },
                      on: { change: _vm.updateRemarks },
                      model: {
                        value: _vm.additional_field,
                        callback: function ($$v) {
                          _vm.additional_field = $$v
                        },
                        expression: "additional_field",
                      },
                    }),
                    _vm._v(" "),
                    !_vm.eventSlot.length
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: { click: _vm.addTab },
                          },
                          [_vm._v("\n            添加活動場次\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      { attrs: { id: "table_remark", "label-for": "title" } },
                      [
                        _c(
                          "b-tabs",
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.table_remark.en"),
                                },
                              },
                              [
                                _c("tinymceEditor", {
                                  attrs: {
                                    id: "table_remark_content_en",
                                    value: _vm.table_remark.content_en,
                                    height: 200,
                                  },
                                  on: {
                                    writeContent: (evt, id, content) =>
                                      (_vm.table_remark.content_en = content),
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.table_remark.tc"),
                                },
                              },
                              [
                                _c("tinymceEditor", {
                                  attrs: {
                                    id: "table_remark_content_tc",
                                    value: _vm.table_remark.content_tc,
                                    height: 200,
                                  },
                                  on: {
                                    writeContent: (evt, id, content) =>
                                      (_vm.table_remark.content_tc = content),
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.table_remark.sc"),
                                },
                              },
                              [
                                _c("tinymceEditor", {
                                  attrs: {
                                    id: "table_remark_content_sc",
                                    value: _vm.table_remark.content_sc,
                                    height: 200,
                                  },
                                  on: {
                                    writeContent: (evt, id, content) =>
                                      (_vm.table_remark.content_sc = content),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { height: "30px" } }),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: "modal-editslots",
                          title: "選擇場次",
                          size: "xl",
                          "hide-footer": "",
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: { click: _vm.selectAllRows },
                          },
                          [_vm._v("Select All")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: { click: _vm.clearSelected },
                          },
                          [
                            _vm._v(
                              "\n              Clear selected\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v("\n              地點：\n              "),
                            _c("b-form-select", {
                              staticStyle: { width: "300px" },
                              attrs: { size: "sm", options: _vm.venueList },
                              model: {
                                value: _vm.selectedVenue,
                                callback: function ($$v) {
                                  _vm.selectedVenue = $$v
                                },
                                expression: "selectedVenue",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "outline-success",
                                  size: "sm",
                                },
                                on: { click: _vm.editSlotsVenue },
                              },
                              [
                                _vm._v(
                                  "\n                Apply Changes\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("b-table", {
                          ref: "selectableTable",
                          staticClass: "es-table mt-3",
                          attrs: {
                            id: "es-table",
                            striped: "",
                            hover: "",
                            small: "",
                            "select-mode": "multi",
                            selectable: "",
                            "selected-variant": "info",
                            items: _vm.eventSlot,
                            fields: _vm.eventSlot_fields,
                          },
                          on: { "row-selected": _vm.onRowSelected },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(selected)",
                              fn: function (row) {
                                return [
                                  row.rowSelected
                                    ? [
                                        _c(
                                          "b-iconstack",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                stacked: "",
                                                variant: "primary",
                                                icon: "square-fill",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("b-icon", {
                                              attrs: {
                                                stacked: "",
                                                variant: "white",
                                                icon: "check",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("Selected"),
                                        ]),
                                      ]
                                    : [
                                        _c(
                                          "b-iconstack",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                stacked: "",
                                                icon: "square-fill",
                                                variant: "white",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("Not selected"),
                                        ]),
                                      ],
                                ]
                              },
                            },
                            {
                              key: "cell(slot)",
                              fn: function (row) {
                                return [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(`${
                                        row.item.venue &&
                                        _vm.getVenue(row.item.venue)
                                          ? _vm.getVenue(row.item.venue).text
                                          : ""
                                      }
                  ${row.item.venue}
                  ${row.item.date}
                  ${row.item.startTime}`) +
                                      "\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tabs",
                      {
                        model: {
                          value: _vm.activeTab,
                          callback: function ($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab",
                        },
                      },
                      _vm._l(_vm.eventSlot, function (eslot, counter) {
                        return _c(
                          "b-tab",
                          {
                            key: eslot.id,
                            staticClass: "tab",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            eslot.venue + " " + eslot.date
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            "border-radius": "500%",
                                          },
                                          attrs: {
                                            size: "sm",
                                            variant: "warning",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyTab(counter)
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "clipboard",
                                              "aria-hidden": "true",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            "border-radius": "500%",
                                          },
                                          attrs: {
                                            size: "sm",
                                            variant: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteTab(counter)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  ×\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { attrs: { id: "slots", "label-for": "slots" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "success" },
                                    on: { click: _vm.addTab },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  添加活動場次\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-editslots",
                                        modifiers: { "modal-editslots": true },
                                      },
                                    ],
                                    attrs: { variant: "outline-primary" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  修改 全部/部份場次\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(_vm._s(_vm.$t("event.date"))),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            required: "",
                                            trim: "",
                                            formatter: _vm.format,
                                          },
                                          on: { change: _vm.reorderTabs },
                                          model: {
                                            value: eslot.date,
                                            callback: function ($$v) {
                                              _vm.$set(eslot, "date", $$v)
                                            },
                                            expression: "eslot.date",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "ie-hint" }, [
                                          _vm._v("yyyy-mm-dd"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.$t("event.venue")) +
                                              "\n                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-select", {
                                          attrs: { options: _vm.venueList },
                                          model: {
                                            value: eslot.venue,
                                            callback: function ($$v) {
                                              _vm.$set(eslot, "venue", $$v)
                                            },
                                            expression: "eslot.venue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("event.start_time"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "time",
                                            required: "",
                                            trim: "",
                                            placeholder:
                                              _vm.$t("event.start_time"),
                                          },
                                          model: {
                                            value: eslot.startTime,
                                            callback: function ($$v) {
                                              _vm.$set(eslot, "startTime", $$v)
                                            },
                                            expression: "eslot.startTime",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "ie-hint" }, [
                                          _vm._v("hh:mm:ss"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("event.end_time"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "time",
                                            required: "",
                                            trim: "",
                                            placeholder:
                                              _vm.$t("event.end_time"),
                                          },
                                          model: {
                                            value: eslot.endTime,
                                            callback: function ($$v) {
                                              _vm.$set(eslot, "endTime", $$v)
                                            },
                                            expression: "eslot.endTime",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "ie-hint" }, [
                                          _vm._v("hh:mm:ss"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "12", md: "6" } },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t("event.ticket")
                                                        ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "event.student"
                                                          )
                                                        ) +
                                                        "）\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                    "lazy-formatter": "",
                                                    formatter:
                                                      _vm.priceFormatter,
                                                    placeholder:
                                                      _vm.$t("event.ticket"),
                                                  },
                                                  on: {
                                                    input: () => {
                                                      if (
                                                        eslot.fee.student === ""
                                                      )
                                                        eslot.fee.student = null
                                                    },
                                                  },
                                                  model: {
                                                    value: eslot.fee.student,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot.fee,
                                                        "student",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "eslot.fee.student",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t("event.ticket")
                                                        ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "event.teacher"
                                                          )
                                                        ) +
                                                        "）\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                    "lazy-formatter": "",
                                                    formatter:
                                                      _vm.priceFormatter,
                                                    placeholder:
                                                      _vm.$t("event.ticket"),
                                                  },
                                                  on: {
                                                    input: () => {
                                                      if (
                                                        eslot.fee.teacher === ""
                                                      )
                                                        eslot.fee.teacher = null
                                                    },
                                                  },
                                                  model: {
                                                    value: eslot.fee.teacher,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot.fee,
                                                        "teacher",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "eslot.fee.teacher",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t("event.ticket")
                                                        ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "event.parents"
                                                          )
                                                        ) +
                                                        "）\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                    "lazy-formatter": "",
                                                    formatter:
                                                      _vm.priceFormatter,
                                                    placeholder:
                                                      _vm.$t("event.ticket"),
                                                  },
                                                  on: {
                                                    input: () => {
                                                      if (
                                                        eslot.fee.parent === ""
                                                      )
                                                        eslot.fee.parent = null
                                                    },
                                                  },
                                                  model: {
                                                    value: eslot.fee.parent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot.fee,
                                                        "parent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "eslot.fee.parent",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t("event.ticket")
                                                        ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$t("event.cssa")
                                                        ) +
                                                        "）\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                    "lazy-formatter": "",
                                                    formatter:
                                                      _vm.priceFormatter,
                                                    placeholder:
                                                      _vm.$t("event.ticket"),
                                                  },
                                                  on: {
                                                    input: () => {
                                                      if (eslot.fee.cssa === "")
                                                        eslot.fee.cssa = null
                                                    },
                                                  },
                                                  model: {
                                                    value: eslot.fee.cssa,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot.fee,
                                                        "cssa",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "eslot.fee.cssa",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "450px" } },
                                          [
                                            _c("p", { staticClass: "label" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("event.target.title")
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-form-checkbox-group", {
                                              attrs: {
                                                options: _vm.target_options,
                                              },
                                              model: {
                                                value: eslot.target,
                                                callback: function ($$v) {
                                                  _vm.$set(eslot, "target", $$v)
                                                },
                                                expression: "eslot.target",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("event.quota")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    required: "",
                                                    trim: "",
                                                    placeholder:
                                                      _vm.$t("event.quota"),
                                                  },
                                                  model: {
                                                    value: eslot.quota,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot,
                                                        "quota",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "eslot.quota",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "event.remain_quota"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    trim: "",
                                                    placeholder:
                                                      _vm.$t(
                                                        "event.remain_quota"
                                                      ),
                                                  },
                                                  model: {
                                                    value: eslot.remain_quota,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        eslot,
                                                        "remain_quota",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "eslot.remain_quota",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("event.status"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-select", {
                                          attrs: {
                                            type: "number",
                                            options: _vm.statusList,
                                            trim: "",
                                            placeholder: _vm.$t("event.status"),
                                          },
                                          model: {
                                            value: eslot.status,
                                            callback: function ($$v) {
                                              _vm.$set(eslot, "status", $$v)
                                            },
                                            expression: "eslot.status",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("event.applyInfo")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _vm.getParentSchemeId(_vm.scheme) === 5
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { md: "6" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("節目形式"),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.haveAdditionalEvent,
                                    callback: function ($$v) {
                                      _vm.haveAdditionalEvent = $$v
                                    },
                                    expression: "haveAdditionalEvent",
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", [_vm._v("e.g. 演出暨演後座談會")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      { attrs: { id: "applyRemark", "label-for": "title" } },
                      [
                        _c(
                          "b-tabs",
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.applyRemark.en"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.applyRemark.content_en,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.applyRemark.content_en =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.applyRemark.tc"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.applyRemark.content_tc,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.applyRemark.content_tc =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.applyRemark.sc"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.applyRemark.content_sc,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.applyRemark.content_sc =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.$t("event.successRemarkInfo")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { id: "successRemark", "label-for": "title" } },
                      [
                        _c(
                          "b-tabs",
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.successRemark.en"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.successRemark.content_en,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.successRemark.content_en =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.successRemark.tc"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.successRemark.content_tc,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.successRemark.content_tc =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: _vm.$t("event.successRemark.sc"),
                                },
                              },
                              [
                                _c("medium-editor", {
                                  staticClass: "medium-editor",
                                  attrs: {
                                    text: _vm.successRemark.content_sc,
                                    options: _vm.options,
                                  },
                                  on: {
                                    edit: (operation) => {
                                      _vm.successRemark.content_sc =
                                        operation.api.origElements.innerHTML
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.getParentSchemeId(_vm.scheme) === 5
                  ? _c("h1", [_vm._v("AES 專用")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getParentSchemeId(_vm.scheme) === 5
                  ? _c(
                      "div",
                      { staticClass: "shadow" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { md: "6" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("節目聯絡人"),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.aesContact,
                                    callback: function ($$v) {
                                      _vm.aesContact = $$v
                                    },
                                    expression: "aesContact",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { md: "6" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("節目聯絡人電話"),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.aesContactTel,
                                    callback: function ($$v) {
                                      _vm.aesContactTel = $$v
                                    },
                                    expression: "aesContactTel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { md: "6" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("支票抬頭(中)"),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.aesContactChequePayableTc,
                                    callback: function ($$v) {
                                      _vm.aesContactChequePayableTc = $$v
                                    },
                                    expression: "aesContactChequePayableTc",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-3", attrs: { md: "6" } },
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("支票抬頭(En)"),
                                ]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.aesContactChequePayableEn,
                                    callback: function ($$v) {
                                      _vm.aesContactChequePayableEn = $$v
                                    },
                                    expression: "aesContactChequePayableEn",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateAes()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Update AES contact\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getParentSchemeId(_vm.scheme) === 5
                  ? _c(
                      "div",
                      { staticClass: "shadow" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: "true",
                                      "unchecked-value": "false",
                                    },
                                    model: {
                                      value: _vm.hasPrePerformanceWorkshop,
                                      callback: function ($$v) {
                                        _vm.hasPrePerformanceWorkshop = $$v
                                      },
                                      expression: "hasPrePerformanceWorkshop",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "event.hasPrePerformanceWorkshop"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { xs: "12" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: "true",
                                      "unchecked-value": "false",
                                    },
                                    model: {
                                      value: _vm.hasFreeTeacherTicket,
                                      callback: function ($$v) {
                                        _vm.hasFreeTeacherTicket = $$v
                                      },
                                      expression: "hasFreeTeacherTicket",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("event.hasFreeTeacherTicket")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getParentSchemeId(_vm.scheme) === 4
                  ? _c(
                      "div",
                      [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.getSchemeSlug(_vm.scheme).toUpperCase()
                            ) + " 申請選項"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { md: "2" } }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "2.5" } },
                                [_vm._v("預覽申請選項：")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              [
                                _c("b-form-select", {
                                  attrs: { options: _vm.eventlist_spaps },
                                  model: {
                                    value: _vm.spapsSelected,
                                    callback: function ($$v) {
                                      _vm.spapsSelected = $$v
                                    },
                                    expression: "spapsSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "shadow" },
                          [
                            _vm._l(_vm.spapsGroup, function (group, index) {
                              return _c(
                                "b-form-group",
                                {
                                  key: index,
                                  attrs: {
                                    "label-for": "spapsGroup",
                                    label: "選擇".concat(index + 1),
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c("b-col", { attrs: { md: "3" } }, [
                                        _c("div", [_vm._v("計劃代號")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "eg C / C(延續)",
                                                type: "text",
                                              },
                                              model: {
                                                value: group.code,
                                                callback: function ($$v) {
                                                  _vm.$set(group, "code", $$v)
                                                },
                                                expression: "group.code",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "title",
                                                "label-for": "title",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-tabs",
                                                [
                                                  _c(
                                                    "b-tab",
                                                    {
                                                      attrs: {
                                                        title: "計劃名稱(英)",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          trim: "",
                                                        },
                                                        model: {
                                                          value: group.title_en,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              group,
                                                              "title_en",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "group.title_en",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-tab",
                                                    {
                                                      attrs: {
                                                        title: "計劃名稱(繁)",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          trim: "",
                                                        },
                                                        model: {
                                                          value: group.title_tc,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              group,
                                                              "title_tc",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "group.title_tc",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-tab",
                                                    {
                                                      attrs: {
                                                        title: "計劃名稱(簡)",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          trim: "",
                                                        },
                                                        model: {
                                                          value: group.title_sc,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              group,
                                                              "title_sc",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "group.title_sc",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  " 如適用，e.g. 音樂劇計劃 / 音樂劇歌舞計劃 "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "quota-txt" },
                                            [_vm._v("名額(小學)")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "小學組",
                                              type: "number",
                                            },
                                            model: {
                                              value: group.grpPrimary.quota,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  group.grpPrimary,
                                                  "quota",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "group.grpPrimary.quota",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "quota-txt" },
                                            [_vm._v("名額(中學)")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "中學組",
                                              type: "number",
                                            },
                                            model: {
                                              value: group.grpSecondary.quota,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  group.grpSecondary,
                                                  "quota",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "group.grpSecondary.quota",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "quota-txt" },
                                            [_vm._v("名額(特殊學校)")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "特殊學校組",
                                              type: "number",
                                            },
                                            model: {
                                              value: group.grpSpecial.quota,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  group.grpSpecial,
                                                  "quota",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "group.grpSpecial.quota",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-tabs",
                                            [
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(小學)(英)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpPrimary
                                                          .title_en,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpPrimary,
                                                          "title_en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpPrimary.title_en",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(小學)(繁)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpPrimary
                                                          .title_tc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpPrimary,
                                                          "title_tc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpPrimary.title_tc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(小學)(簡)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpPrimary
                                                          .title_sc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpPrimary,
                                                          "title_sc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpPrimary.title_sc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-tabs",
                                            [
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(中學)(英)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSecondary
                                                          .title_en,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSecondary,
                                                          "title_en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSecondary.title_en",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(中學)(繁)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSecondary
                                                          .title_tc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSecondary,
                                                          "title_tc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSecondary.title_tc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "組別名稱(中學)(簡)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSecondary
                                                          .title_sc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSecondary,
                                                          "title_sc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSecondary.title_sc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-tabs",
                                            [
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title:
                                                      "組別名稱(特殊學校)(英)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSpecial
                                                          .title_en,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSpecial,
                                                          "title_en",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSpecial.title_en",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title:
                                                      "組別名稱(特殊學校)(繁)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSpecial
                                                          .title_tc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSpecial,
                                                          "title_tc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSpecial.title_tc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title:
                                                      "組別名稱(特殊學校)(簡)",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      trim: "",
                                                    },
                                                    model: {
                                                      value:
                                                        group.grpSpecial
                                                          .title_sc,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          group.grpSpecial,
                                                          "title_sc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "group.grpSpecial.title_sc",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              " 如適用，e.g. 中學入門組 / 延續組"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(
                                              "\n                    若沒有填寫則會顯示「小學組」/「中學組」/「特殊學校組」\n                  "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn_img_del",
                                      attrs: { variant: "outline-danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delSpapsGroup(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                刪除選擇" +
                                          _vm._s(index + 1) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-success" },
                                on: { click: _vm.addSpapsGroup },
                              },
                              [_vm._v("\n              添加選擇\n            ")]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "customizeLastModify",
                      "label-for": "customizeLastModify",
                    },
                  },
                  [
                    _c("p", { staticClass: "label" }, [_vm._v("最後修改日期")]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: { id: "customizeLastModify", type: "date" },
                      model: {
                        value: _vm.customizeLastModify,
                        callback: function ($$v) {
                          _vm.customizeLastModify = $$v
                        },
                        expression: "customizeLastModify",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "disabled" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { value: "1", "unchecked-value": "0" },
                        model: {
                          value: _vm.disabled,
                          callback: function ($$v) {
                            _vm.disabled = $$v
                          },
                          expression: "disabled",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("event.disabled")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { value: "2", "unchecked-value": "0" },
                        model: {
                          value: _vm.disabled,
                          callback: function ($$v) {
                            _vm.disabled = $$v
                          },
                          expression: "disabled",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("event.web_disabled")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          variant: "outline-success",
                          type: "submit",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          variant: "outline-success",
                          type: "submit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setSendEmail()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit_and_send_mail")))]
                    ),
                    _vm._v(" "),
                    _vm.scheme != 129
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              variant: _vm.checkNotAblePreview
                                ? ""
                                : "outline-primary",
                              disabled: _vm.checkNotAblePreview,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.previewEplatform()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("action.preview")) +
                                " (E-Platform)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: _vm.checkNotAblePreview
                            ? ""
                            : "outline-warning",
                          disabled: _vm.checkNotAblePreview,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewWebpage()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("action.preview")) +
                            " (Webpage)\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }