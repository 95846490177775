<template>
  <div>
    <b-modal
      id="modal-contactSch"
      v-model="show"
      size="lg"
      title="Confirm Result"
      header-bg-variant="dark"
      header-text-variant="light"
      content-class="modal-application-content"
      scrollable
      @hide="
        () => {
          if (hasEdited) $parent.refresh()
        }
      "
    >
      <div v-if="selectedApplication">
        <b-row>
          <b-col>
            <b-table
              stacked
              class="application-modal"
              :fields="fields"
              :items="getArray(selectedApplication)"
            >
              <template #cell(TotalTickets)="row">
                {{
                  row.item.firstChoiceStudentNo +
                  row.item.firstChoiceTeacherNo +
                  row.item.firstChoiceParentNo
                }}
              </template>
              <template #cell(grandTotal)="row">
                {{
                  row.item.data &&
                  row.item.data.aes &&
                  row.item.data.aes.confirmedPrice
                    ? `$${getConfirmedPrice(row.item.data.aes.confirmedPrice)}`
                    : ''
                }}
              </template>
              <template #cell(ApplicationResult)="row">
                {{
                  row.item.data &&
                  row.item.data.aes &&
                  row.item.data.aes.applicationResult
                    ? `${row.item.data.aes.applicationResult}`
                    : ''
                }}
              </template>
              <!-- <template #cell(dateTime)="row">
                {{ getSlot(row.item.event_slot_1, 'dateTime') }}
              </template>
              <template #cell(venue)="row">
                {{ getSlot(row.item.event_slot_1, 'venue') }}
              </template>
              <template #cell(venueTxt)="row">
                {{ getSlot(row.item.event_slot_1, 'venueTxt') }}
              </template> -->
            </b-table>
          </b-col>
          <b-col>
            <table width="100%">
              <tr>
                <th>
                  申請狀&zwj;態：
                  <b-icon
                    v-b-popover.hover.top="
                      '老師用戶可於「我的申請」中查閱申請狀態'
                    "
                    icon="exclamation-circle-fill"
                    variant="warning"
                  ></b-icon>
                </th>
                <td>
                  <div v-if="isEditStatus">
                    <b-form-select
                      v-model="status"
                      :options="statusOptions"
                    ></b-form-select>
                  </div>
                  <h3 v-else>{{ getStatusTxt(status) }}</h3>
                </td>
              </tr>

              <tr>
                <td>付款（Admin專用）：</td>
                <td>
                  <div v-if="isEditStatus">
                    <b-form-select
                      v-model="aes.applicationResult"
                      :options="resultOptions"
                    ></b-form-select>
                  </div>
                  <div v-else class="field">{{ aes.applicationResult }}</div>
                </td>
              </tr>

              <tr v-if="isEditStatus">
                <td colspan="2" align="right">
                  <b-button variant="primary" @click="submitStatus">
                    Submit
                  </b-button>
                  <b-button @click="cancelStatus">Cancel</b-button>
                </td>
              </tr>
              <tr v-else-if="!isEditPrice && !isEditCheque">
                <td></td>
                <td align="left">
                  <b-button
                    v-b-modal.AES_SUCCESS
                    class="mt-2"
                    size="sm"
                    variant="outline-secondary"
                    disable
                  >
                    發送成功通知
                  </b-button>
                  <b-button
                    v-b-modal.AES_WAITING
                    class="mt-2"
                    size="sm"
                    variant="outline-warning"
                    disable
                  >
                    發送候補通知
                  </b-button>
                  <b-button
                    v-b-modal.AES_CANCEL
                    class="mt-2"
                    size="sm"
                    variant="outline-info"
                    disable
                  >
                    發送取消通知
                  </b-button>
                  <b-button
                    class="right"
                    variant="link"
                    size="sm"
                    @click="
                      () => {
                        isEditStatus = true
                      }
                    "
                  >
                    編輯
                  </b-button>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>

              <tr>
                <th>免費老師門票數&zwj;目：</th>
                <td>
                  <b-form-input
                    v-if="isEditPrice"
                    v-model="aes.freeTeacherNo"
                    type="number"
                    :readonly="!hasFreeTeacherTicket"
                    :min="0"
                    :max="selectedApplication.firstChoiceTeacherNo"
                    :state="freeTeacherNoState()"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.freeTeacherNo }}</div>
                  <div v-if="!hasFreeTeacherTicket">
                    活動沒有 free teacher ticket
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  電腦估算結&zwj;果：
                  <p>
                    {{
                      getFormulaTxt(
                        selectedApplication.firstChoiceStudentNo,
                        selectedApplication.firstChoiceTeacherNo,
                        selectedApplication.firstChoiceParentNo,
                        selectedApplication.event_slot_1,
                        aes.freeTeacherNo
                      )
                    }}
                    = ${{
                      calFee(
                        selectedApplication,
                        selectedApplication.event_slot_1,
                        aes.freeTeacherNo
                      )
                    }}
                  </p>
                </td>
              </tr>

              <tr>
                <th>確認金額（HKD）：</th>
                <td>
                  <b-form-input
                    v-if="isEditPrice"
                    v-model="aes.confirmedPrice"
                    type="number"
                    placeholder="請輸入確認金額..."
                  ></b-form-input>
                  <div v-else class="field">
                    {{
                      aes.confirmedPrice
                        ? `$${getConfirmedPrice(aes.confirmedPrice)}`
                        : ''
                    }}
                  </div>
                </td>
              </tr>

              <tr v-if="isEditPrice">
                <td colspan="2" align="right">
                  <b-button variant="primary" @click="submitAes">
                    Submit
                  </b-button>
                  <b-button @click="cancelAes">Cancel</b-button>
                </td>
              </tr>
              <tr v-else-if="!isEditStatus && !isEditCheque">
                <td></td>
                <td align="left">
                  <b-button
                    v-b-modal.AES_PAYMENT
                    class="mt-2"
                    size="sm"
                    variant="outline-secondary"
                  >
                    發送付款通知
                  </b-button>
                  <b-button
                    class="right mt-2"
                    variant="link"
                    size="sm"
                    @click="
                      () => {
                        isEditPrice = true
                      }
                    "
                    >編輯</b-button
                  >
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>

              <tr>
                <th>支票</th>
                <td></td>
              </tr>

              <tr>
                <td>支票抬頭：</td>
                <td>
                  <b-form-input
                    v-if="isEditCheque"
                    v-model="aes.cheque"
                    type="text"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.cheque }}</div>
                </td>
              </tr>

              <tr>
                <td>Bank:</td>
                <td>
                  <b-form-input
                    v-if="isEditCheque"
                    v-model="aes.bank"
                    type="text"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.bank }}</div>
                </td>
              </tr>

              <tr>
                <td>Cheque No:</td>
                <td>
                  <b-form-input
                    v-if="isEditCheque"
                    v-model="aes.chequeno"
                    type="text"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.chequeno }}</div>
                </td>
              </tr>

              <tr>
                <td>Amount:</td>
                <td>
                  <b-form-input
                    v-if="isEditCheque"
                    v-model="aes.amount"
                    type="number"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.amount }}</div>
                </td>
              </tr>

              <tr>
                <td>Receive Date:</td>
                <td>
                  <b-form-input
                    v-if="isEditCheque"
                    v-model="aes.receiveDate"
                    type="date"
                  ></b-form-input>
                  <div v-else class="field">{{ aes.receiveDate }}</div>
                </td>
              </tr>
              <!-- <tr>
                <th>發送確認通知：</th>
                <td>#構建中</td>
              </tr>
 -->
              <tr v-if="isEditCheque">
                <td colspan="2" align="right">
                  <b-button variant="primary" @click="submitAes">
                    Submit
                  </b-button>
                  <b-button @click="cancelAes">Cancel</b-button>
                </td>
              </tr>
              <tr v-else-if="!isEditStatus && !isEditPrice">
                <td></td>
                <td align="left">
                  <b-button
                    v-b-modal.AES_CONFIRM
                    class="mt-2"
                    size="sm"
                    variant="outline-secondary"
                  >
                    發送確認通知
                  </b-button>
                  <b-button
                    class="right mt-2"
                    variant="link"
                    size="sm"
                    @click="
                      () => {
                        isEditCheque = true
                      }
                    "
                    >編輯</b-button
                  >
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div class="btn-apply" @click="show = false">返回</div>
      </template>
    </b-modal>
    <div v-if="selectedApplication" :key="selectedApplication.id">
      <email-modal
        v-if="selectedApplication"
        :key="selectedApplication.id"
        :trn-id="selectedApplication.trnId"
        code="AES_SUCCESS"
        :custom-api="
          getCustomApi(
            cloudApiUrl + 'contacts/aes/contact/',
            selectedApplication.event_id
          )
        "
      />
      <email-modal
        v-if="selectedApplication"
        :trn-id="selectedApplication.trnId"
        code="AES_WAITING"
        :custom-api="
          getCustomApi(
            cloudApiUrl + 'contacts/aes/contact/',
            selectedApplication.event_id
          )
        "
      />
      <email-modal
        v-if="selectedApplication"
        :trn-id="selectedApplication.trnId"
        code="AES_PAYMENT"
        :custom-data="getCustomData(aes)"
        :custom-api="
          getCustomApi(
            cloudApiUrl + 'contacts/aes/contact/',
            selectedApplication.event_id
          )
        "
        :has-free-teacher-ticket="hasFreeTeacherTicket"
      />
      <email-modal
        v-if="selectedApplication"
        :trn-id="selectedApplication.trnId"
        code="AES_CONFIRM"
        :custom-api="
          getCustomApi(
            cloudApiUrl + 'contacts/aes/contact/',
            selectedApplication.event_id
          )
        "
      />
      <email-modal
        v-if="selectedApplication"
        :trn-id="selectedApplication.trnId"
        code="AES_CANCEL"
        :custom-api="
          getCustomApi(
            cloudApiUrl + 'contacts/aes/contact/',
            selectedApplication.event_id
          )
        "
      />
    </div>
  </div>
</template>

<script>
import EmailModal from '~/components/admin/application/emailModal.vue'
export default {
  components: { EmailModal },
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedApplication', 'events'],
  data() {
    let hasPrePerformanceWorkshop = false
    let hasFreeTeacherTicket = false
    if (this?.selectedApplication?.data?.aes?.custom_config) {
      const customConfig = JSON.parse(
        this.selectedApplication.data.aes.custom_config
      )
      if (customConfig?.hasPrePerformanceWorkshop) {
        hasPrePerformanceWorkshop = customConfig.hasPrePerformanceWorkshop
      }
      if (customConfig?.hasFreeTeacherTicket) {
        hasFreeTeacherTicket = customConfig.hasFreeTeacherTicket
      }
    }
    return {
      cloudApiUrl: process.env.cloudApiUrl, // declare Cloud Api Url
      show: false,
      status: this.selectedApplication ? this.selectedApplication.status : null,
      hasEdited: false,
      isEditStatus: false,
      isEditPrice: false,
      isEditCheque: false,
      hasPrePerformanceWorkshop,
      hasFreeTeacherTicket,
      extraInput: {},
      aes:
        this.selectedApplication && this.selectedApplication.data
          ? this.selectedApplication.data.aes
          : {},
      resultOptions: [
        { value: 'Paid', text: 'Paid' },
        { value: '', text: 'Pending' },
      ],
      statusOptions: [
        { value: 1, text: 'Accepted（獲接納）' },
        { value: 0, text: 'Waiting（處理中）' },
        { value: 3, text: 'Cancel（已取消）' },
      ],
      fields: [
        { key: 'trnId' },
        { key: 'submitTime' },
        { key: 'updated_at' },
        { key: 'activityName', label: 'Programme' },
        /* { key: 'Format' }, */
        { key: 'event_slot_1', label: 'Event Slot No.' },
        { key: 'dateTime', label: 'Date/Time' },
        /* { key: 'firstChoiceDate', label: 'Date' },
        { key: 'firstChoiceTime', label: 'Time' }, */
        { key: 'Venue' },
        { key: 'venueTxt', label: '場地' },
        { key: 'firstChoiceStudentNo', label: 'Total No. of Students' },
        { key: 'firstChoiceTeacherNo', label: 'Total No. of Teachers' },
        { key: 'firstChoiceParentNo', label: 'Total No. of CSSA' },
        { key: 'TotalTickets' },
        { key: 'grandTotal', label: '$ Grand Total' },
        { key: 'Capacity' },
        { key: 'schoolId' },
        { key: 'schoolName' },
        { key: 'Address' },
        { key: 'Principal' },
        { key: 'ApplicationResult' },
        { key: 'ReceivePromotionInFuture' },
        { key: 'userId' },
        { key: 'user_name' },
        { key: 'user_tel' },
        { key: 'user_email' },
        { key: 'additionTeachers' },
        /* { key: 'id' }, */
        /* { key: 'formId' },
        { key: 'schemeId' },
        { key: 'activityCode' },
        { key: 'event_id' },
        { key: 'firstChoiceClass' },
        { key: 'status' },
        { key: 'result_choice' },
        { key: 'result_event_slot' },
        { key: 'result_quota' },
        { key: 'ogcio' },
        { key: 'data' },
        { key: 'intSchoolNameChi' }, */
      ],
    }
  },
  computed: {},
  watch: {
    selectedApplication: {
      handler(newVal, oldVal) {
        this.status = newVal ? newVal.status : null
        if (newVal && newVal.data && typeof newVal.data.aes === 'object')
          this.aes = JSON.parse(JSON.stringify(newVal.data.aes)) || {}
        if (newVal && newVal.data && typeof newVal.data.aes === 'string')
          this.aes = JSON.parse(newVal.data.aes) || {}

        if (newVal?.custom_config) {
          const customConfig = JSON.parse(newVal.custom_config)
          if (customConfig?.hasPrePerformanceWorkshop) {
            this.hasPrePerformanceWorkshop =
              customConfig.hasPrePerformanceWorkshop
          }
          if (customConfig?.hasFreeTeacherTicket) {
            this.hasFreeTeacherTicket = customConfig.hasFreeTeacherTicket
          }
        }
        if (newVal?.extra_input) {
          const extraInput = JSON.parse(newVal.extra_input)
          if (extraInput?.firstPre) {
            this.extraInput.firstPre = extraInput.firstPre
          }
          if (extraInput?.secondPre) {
            this.extraInput.secondPre = extraInput.secondPre
          }
          if (extraInput?.thirdPre) {
            this.extraInput.thirdPre = extraInput.thirdPre
          }
        }
        this.selectedApplication = {
          ...this.selectedApplication,
          firstPreDate: this.extraInput.firstPre
            ? this.extraInput.firstPre
            : 'N/A',
          secondPreDate: this.extraInput.secondPre
            ? this.extraInput.secondPre
            : 'N/A',
          thirdPreDate: this.extraInput.thirdPre
            ? this.extraInput.thirdPre
            : 'N/A',
        }
        this.isEditStatus = false
        this.isEditPrice = false
        this.isEditCheque = false
        this.hasEdited = false
        this.$forceUpdate()
      },
      deep: true,
    },
    events: {
      immediate: true,
      handler(newData) {
        if (newData?.[0]?.custom_config) {
          const customConfig = JSON.parse(newData[0].custom_config)
          if (customConfig?.hasPrePerformanceWorkshop) {
            this.hasPrePerformanceWorkshop =
              customConfig.hasPrePerformanceWorkshop
          }
          if (customConfig?.hasFreeTeacherTicket) {
            this.hasFreeTeacherTicket = customConfig.hasFreeTeacherTicket
          }
          console.log(this.hasFreeTeacherTicket)
        }
      },
    },
    hasPrePerformanceWorkshop: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.fields = [
            ...this.fields,
            {
              key: 'firstPreDate',
              label: 'First Pre-Performance Workshop Timeslot',
            },
            {
              key: 'secondPreDate',
              label: 'Second Pre-Performance Workshop Timeslot',
            },
            {
              key: 'thirdPreDate',
              label: 'Third Pre-Performance Workshop Timeslot',
            },
          ]
        }
      },
    },
    // extraInput: {
    //   immediate: true,
    //   handler() {
    //     console.log(this.selectedApplication)
    //     // this.selectedApplication = {
    //     //   ...this.selectedApplication,
    //     //   firstPreDate: this.extraInput.firstPre
    //     //     ? this.extraInput.firstPre
    //     //     : 'N/A',
    //     //   secondPreDate: this.extraInput.secondPre
    //     //     ? this.extraInput.secondPre
    //     //     : 'N/A',
    //     //   thirdPreDate: this.extraInput.thirdPre
    //     //     ? this.extraInput.thirdPre
    //     //     : 'N/A',
    //     // }
    //   },
    // },
  },
  methods: {
    getCustomApi(url, eventId) {
      return url + eventId
    },
    getConfirmedPrice(data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getCustomData(data) {
      const tmp = JSON.parse(JSON.stringify(data))
      if (tmp.confirmedPrice) {
        tmp.confirmedPrice = this.getConfirmedPrice(tmp.confirmedPrice)
      }
      return tmp
    },
    getSlot(slotid, type) {
      const slot = this.getEventSlotById(slotid)
      switch (type) {
        case 'dateTime':
          return `${this.$moment(slot.date).format('D.M.y (ddd)')}
          ${this.$moment(`2021-01-01 ${slot.startTime}`).format('h:mm a')}`
        case 'venue':
          return slot.venue
        case 'venueTxt':
          return slot.venue
        default:
          return ''
      }
    },
    freeTeacherNoState() {
      return (
        this.aes.freeTeacherNo > 0 &&
        this.aes.freeTeacherNo <= this.selectedApplication.firstChoiceTeacherNo
      )
    },
    async submitEdit() {
      if (typeof this.selectedApplication.firstChoiceClass === 'string')
        this.selectedApplication.firstChoiceClass = JSON.parse(
          this.selectedApplication.firstChoiceClass
        )
      const submit = await this.$swal({
        title: this.$t('alert.submit'),
        text: `確定修改 ${this.selectedApplication.schoolName} 的申請紀錄?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `${this.$t('alert.yes')}`,
        confirmButtonColor: '#28a745', // '#d9534f',
        cancelButtonText: `${this.$t('alert.no')}`,
      })

      if (submit.value) {
        this.hasEdited = true
        try {
          const result = this.callApiUpdateApplication(process.env.localApiUrl)
          if (result.error !== undefined) throw new Error(result.error)
          const result2 = this.callApiUpdateApplication(process.env.cloudApiUrl)
          if (result2.error !== undefined) throw new Error(result2.error)

          const back = await this.$swal({
            title: this.$t('alert.title'),
            text: `${this.$t('alert.content')}： ${this.$t(
              'alert.edit'
            )}${this.$t('alert.success')}`,
            icon: 'question',
            confirmButtonText: this.$t('alert.backEdit'),
            confirmButtonColor: '#d9534f',
          })
          if (back.value) {
            // this.$router.go(-1)
            // this.$router.push(this.localePath('/admin/event'))
            await this.$forceUpdate()
          }
        } catch (err) {
          // console.log(err)
          this.error = this.$t('error.' + err.response.data.error)
        }
      }
    },
    async callApiUpdateApplication(api) {
      try {
        return await this.$axios.$post(`${api}applications/update`, {
          id: this.selectedApplication.id,
          trnId: this.selectedApplication.trnId,
          schoolId: this.selectedApplication.schoolId,
          userId: this.selectedApplication.userId,
          firstChoiceDate: this.selectedApplication.firstChoiceDate
            ? this.$moment(this.selectedApplication.firstChoiceDate).format(
                'YYYY-MM-DD'
              )
            : null,
          firstChoiceTime: this.selectedApplication.firstChoiceTime,
          firstChoiceStudentNo: this.selectedApplication.firstChoiceStudentNo,
          firstChoiceTeacherNo: this.selectedApplication.firstChoiceTeacherNo,
          firstChoiceParentNo: this.selectedApplication.firstChoiceParentNo,
          firstChoiceClass:
            this.selectedApplication.firstChoiceClass.length > 0
              ? this.selectedApplication.firstChoiceClass
              : [],
          event_slot_1: this.selectedApplication.event_slot_1,
          status: this.selectedApplication.status,
          result_choice: this.selectedApplication.result_choice,
          result_event_slot: this.selectedApplication.result_event_slot,
          result_quota: this.selectedApplication.result_quota,
          remarks:
            JSON.stringify(this.selectedApplication.remarks) !== '{}'
              ? this.selectedApplication.remarks
              : {},
          additionTeachers:
            this.selectedApplication.additionTeachers.length > 0
              ? this.selectedApplication.additionTeachers
              : [],
          data: this.aes
            ? JSON.stringify({
                aes: this.aes,
              })
            : null,
        })
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    //
    async submitStatus() {
      this.isEditStatus = false
      this.selectedApplication.status = this.status

      this.isEditPrice = false
      this.isEditCheque = false

      if (!this.selectedApplication.data) this.selectedApplication.data = {}
      this.selectedApplication.data.aes = JSON.parse(JSON.stringify(this.aes))

      await this.submitEdit()
      await this.$forceUpdate()
    },
    cancelStatus() {
      this.status = this.selectedApplication.status
      this.isEditStatus = false
    },
    async submitAes() {
      this.isEditPrice = false
      this.isEditCheque = false
      if (!this.selectedApplication.data) this.selectedApplication.data = {}
      this.selectedApplication.data.aes = JSON.parse(JSON.stringify(this.aes))
      await this.submitEdit()
      await this.$forceUpdate()
    },
    cancelAes() {
      if (this.selectedApplication.data && this.selectedApplication.data.aes)
        this.aes = JSON.parse(JSON.stringify(this.selectedApplication.data.aes))
      else this.aes = {}
      this.isEditPrice = false
      this.isEditCheque = false
    },
    getStatusTxt(status) {
      switch (status) {
        case 0:
          return 'Waiting（處理中）'
        case 1:
          return 'Accepted（獲接納）'
        case 3:
          return 'Cancel（已取消）'
        default:
          return ''
      }
    },
    getArray(item) {
      const arr = []
      arr.push(item)
      return arr
    },
    getFormulaTxt(studentNo, teacherNo, cssaNo, slotid, freeTeacherNo) {
      let result = ''

      const studentFee = this.getFee(slotid, 'student')
      const teacherFee = this.getFee(slotid, 'teacher')
      freeTeacherNo = freeTeacherNo || 0

      result = `學生 ${studentNo} x $${studentFee} + 老師 ${
        teacherNo - freeTeacherNo
      } x $${teacherFee}`

      const cssaFee = this.getFee(slotid, 'cssa')
      if (cssaFee) result = `${result} + CSSA ${cssaNo} x $${cssaFee}`

      return result
    },
    calFee(selectedApplication, slotid, freeTeacherNo) {
      freeTeacherNo = freeTeacherNo || 0
      const student =
        selectedApplication.firstChoiceStudentNo *
        this.getFee(slotid, 'student')
      const teacher =
        (selectedApplication.firstChoiceTeacherNo - freeTeacherNo) *
        this.getFee(slotid, 'teacher')
      const cssa =
        selectedApplication.firstChoiceParentNo * this.getFee(slotid, 'cssa') ||
        0
      /* // console.log(
        typeof selectedApplication.firstChoiceParentNo,
        typeof this.getFee(slotid, 'student'),
        student
      ) */
      return student + teacher + cssa
    },
    getFee(id, type) {
      const slot = this.getEventSlotById(id)
      if (slot && slot.fee) {
        if (type === 'student') return parseInt(JSON.parse(slot.fee).student)
        if (type === 'teacher') return parseInt(JSON.parse(slot.fee).teacher)
        if (type === 'cssa') return JSON.parse(slot.fee).cssa
      }
    },
    getEventSlotById(id) {
      let found = null
      this.events.forEach((e) => {
        const eventslot = e.EventSlot.find((d) => d.id === id)
        if (eventslot) found = eventslot
      })
      return found
    },
  },
}
</script>
<style lang="scss">
.application-modal > tbody > tr > td::before {
  text-align: left !important;
}
</style>
<style lang="scss" scoped>
.field {
  background-color: whitesmoke;
  padding: 2px 7px;
  min-height: 25px;
  margin-bottom: 2px;
}
.btn-apply {
  width: 180px;
  border-radius: 20px;
  padding: 7px 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: white;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-apply:hover {
  background-color: #17a79f;
}
.right {
  float: right;
}
.selected-user {
  width: 100%;
  border: whitesmoke 1px solid;
  color: #3f3f3f;
}
.selected-user > tr {
  border: whitesmoke 1px solid;
}
.selected-user > tr > td {
  line-height: 2;
  padding: 5px 20px;
}
.selected-user > tr > td:nth-child(1) {
  width: 130px;
}
</style>
