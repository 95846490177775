<template>
  <b-row align-h="center" class="component">
    <b-col
      sm="12"
      :md="action == 'schoolAdd' || action == 'schoolEdit' ? 12 : 6"
    >
      <div class="pageForm">
        <b-form method="post" @submit.prevent="submit">
          <b-row>
            <b-col>
              <b-form-group
                :id="info.id ? 'name-' + info.id : 'name'"
                label-for="name"
              >
                <p class="label">{{ $t('user.name') }}</p>
                <b-form-input
                  :id="info.id ? 'name-' + info.id : 'name'"
                  v-model="info.name"
                  type="text"
                  :required="action === 'edit' ? false : true"
                  :readonly="
                    (!isEditable && action != 'schoolAdd') ||
                    action == 'schoolEdit'
                      ? true
                      : false
                  "
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :id="info.id ? 'password-' + info.id : 'password'"
                label-for="password"
              >
                <p class="label">
                  {{
                    action === 'edit'
                      ? $t('user.newPassword')
                      : $t('user.password')
                  }}
                </p>
                <b-form-input
                  :id="info.id ? 'password-' + info.id : 'password'"
                  v-model="info.password"
                  :type="action == 'schoolAdd' ? 'text' : 'password'"
                  :required="action === 'edit' ? false : true"
                  :readonly="
                    (!isEditable && action != 'schoolAdd') ||
                    action == 'schoolEdit'
                      ? true
                      : false
                  "
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                :id="info.id ? 'tel-' + info.id : 'tel'"
                label-for="tel"
              >
                <p class="label">{{ $t('user.tel') }}</p>
                <b-form-input
                  :id="info.id ? 'tel-' + info.id : 'tel'"
                  v-model="info.tel"
                  type="text"
                  :required="action === 'edit' ? false : true"
                  :readonly="
                    (!isEditable && action != 'schoolAdd') ||
                    action == 'schoolEdit'
                      ? true
                      : false
                  "
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :id="info.id ? 'email-' + info.id : 'email'"
                label-for="email"
              >
                <p class="label">{{ $t('user.email') }}</p>
                <b-form-input
                  :id="info.id ? 'email-' + info.id : 'email'"
                  v-model="info.email"
                  type="email"
                  :required="action === 'edit' ? false : true"
                  :readonly="
                    (!isEditable && action != 'schoolAdd') ||
                    action == 'schoolEdit'
                      ? true
                      : false
                  "
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="action != 'schoolAdd' && action != 'schoolEdit'">
              <b-form-group
                :id="info.id ? 'school-' + info.id : 'school'"
                label-for="school"
              >
                <p class="label">{{ $t('user.school') }}</p>
                <b-form-input
                  :id="info.id ? 'school-' + info.id : 'school'"
                  v-model="info.school"
                  type="text"
                  :required="
                    info.isEditorAdmin || info.isSuperAdmin ? false : true
                  "
                  :readonly="!isEditable"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="action != 'schoolAdd' && action != 'schoolEdit'">
              <b-form-group
                :id="info.id ? 'isSchoolAdmin-' + info.id : 'isSchoolAdmin'"
              >
                <b-form-checkbox
                  v-model="info.isSchoolAdmin"
                  :disabled="!isEditable"
                  >{{ $t('user.isSchoolAdmin') }}</b-form-checkbox
                >
              </b-form-group>
            </b-col>

            <b-col
              v-if="
                action != 'schoolAdd' &&
                action != 'schoolEdit' &&
                !!info.isSuperAdmin
              "
            >
              <b-form-group
                :id="info.id ? 'isSuperAdmin-' + info.id : 'isSuperAdmin'"
              >
                <b-form-checkbox
                  v-model="info.isSuperAdmin"
                  :disabled="info.isSuperAdmin ? true : false"
                >
                  {{ $t('user.isSuperAdmin') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col v-if="action != 'schoolAdd' && action != 'schoolEdit'">
              <b-form-group
                :id="info.id ? 'isEditorAdmin-' + info.id : 'isEditorAdmin'"
              >
                <b-form-checkbox
                  v-model="info.isEditorAdmin"
                  :disabled="!isEditable"
                  >{{ $t('user.isEditorAdmin') }}</b-form-checkbox
                >
              </b-form-group>
            </b-col>

            <b-col v-if="action != 'schoolAdd' && action != 'schoolEdit'">
              <b-form-group :id="info.id ? 'disabled-' + info.id : 'disabled'">
                <b-form-checkbox
                  v-model="info.disabled"
                  :disabled="!isEditable"
                  >{{ $t('user.disabled') }}</b-form-checkbox
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="info.isEditorAdmin || info.isSuperAdmin">
            <b-col>
              <b-form-group
                :id="info.id ? 'role-' + info.id : 'role'"
                label-for="role"
              >
                <p class="label">{{ $t('navbar_admin.role') }}</p>
                <b-form-select
                  v-model="info.role"
                  :options="roleList"
                  :disabled="info.isSuperAdmin ? true : false"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <p>{{ $t('school.remarks') }}</p>
            <b-row>
              <b-col>
                <b-form-group
                  :id="info.id ? 'remarks-' + info.id : 'remarks'"
                  label-for="remarks"
                >
                  <b-form-input
                    v-model="info.remarks"
                    type="text"
                    :readonly="!isEditable && action != 'schoolAdd'"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <Notification
            v-if="action != 'schoolAdd' && action != 'schoolEdit' && error"
            :message="error"
          />
          <div>
            <b-button
              v-if="
                !isEditable && action != 'schoolAdd' && action != 'schoolEdit'
              "
              type="button"
              variant="outline-secondary"
              @click="toggleEdit()"
              >編輯
            </b-button>
            <b-button
              v-if="
                isEditable && action != 'schoolAdd' && action != 'schoolEdit'
              "
              :id="submit + (info.id ? '-' + info.id : '')"
              type="submit"
              variant="outline-success"
              >{{ $t('action.submit') }}
            </b-button>
            <b-button
              v-if="action != 'schoolAdd' && action != 'schoolEdit'"
              :disabled="isEditable"
              variant="outline-primary"
              @click="sendAcctRegMail()"
              >重新發送「建立帳戶」確認電郵
            </b-button>

            <b-button
              v-if="
                action != 'schoolAdd' &&
                action != 'schoolEdit' &&
                info.attempt === 5
              "
              variant="outline-danger"
              @click="unlock()"
              >解鎖帳戶
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
export default {
  components: {
    Notification,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        email: '',
        password: '',
        remarks: '',
        tel: null,
        school: null,
        isSuperAdmin: false,
        isSchoolAdmin: false,
      }),
    },
  },
  async fetch() {
    try {
      if (this.action === 'edit') {
        const result = await this.$axios.get(`users/${this.$route.params.id}`)
        const cloudResult = await this.$axios.get(
          `${process.env.cloudApiUrl}users/${this.$route.params.id}`
        )

        const data = result.data.data[0]
        // const cloudData = cloudResult.data.data[0]

        this.info.id = data.id
        this.info.name = data.name
        this.info.email = data.email
        this.info.tel = data.tel
        this.info.school = data.school
        this.info.remark = data.remarks
        this.info.isSuperAdmin = data.isSuperAdmin
        this.info.isSchoolAdmin = data.isSchoolAdmin
        this.info.isEditorAdmin = data.isEditorAdmin
        this.info.disabled = data.disabled
        this.info.role = data.role
        this.info.remarks = data.remarks
        // this.info.attempt = cloudData.attempt

        let cloudData = null

        if (
          cloudResult &&
          cloudResult.data &&
          cloudResult.data.data &&
          cloudResult.data.data[0]
        ) {
          cloudData = cloudResult.data.data[0]
        }

        this.info.attempt =
          cloudData && cloudData.attempt ? cloudData.attempt : 0

        // console.log(data)
      }
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }

    try {
      const result = await this.$axios.get(`roles`)
      this.roleList = result.data.data.map((d) => {
        d.value = d.id
        d.text = d.name
        return d
      })
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      isEditable: this.action === 'add',
      error: null,
      info: this.detail,
    }
  },
  computed: {},
  watch: {
    detail: {
      handler(newVal, oldVal) {
        console.log('detail', newVal)
        this.info = newVal
      },
      deep: true,
    },
    info: {
      handler(newVal, oldVal) {
        this.$emit('change', this.info.id, newVal)
      },
      deep: true,
    },
  },
  methods: {
    toggleEdit() {
      event.preventDefault()
      this.isEditable = true
    },
    async sendAcctRegMail() {
      try {
        const syncSchool = await this.$axios.get(
          `${process.env.localApiUrl}sync/sync/school`
        )
        // console.log(result.data.data)
        await this.$axios.post(`${process.env.cloudApiUrl}sync/sync/school`, {
          data: syncSchool.data.data,
        })
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}users/sendAcctRegMail`,
          {
            data: {
              id: this.info.id,
              name: this.info.name,
              password: this.info.password,
              email: this.info.email,
              school: this.info.school,
              isSuperAdmin: this.info.isSuperAdmin,
              isSchoolAdmin: this.info.isSchoolAdmin,
              isEditorAdmin: this.isEditorAdmin,
              disabled: this.info.disabled,
              role: this.info.isEditorAdmin ? this.info.role : null,
              remarks: this.info.remarks,
            },
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        await this.$swal({
          text: `成功發送電郵！`,
          icon: 'question',
          confirmButtonText: 'OK',
          confirmButtonColor: '#d9534f',
        })
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async unlock() {
      try {
        if (this.action === 'edit') {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}users/unlock/${this.info.id}`
          )
          if (result.data && result.data.status === 'ok') {
            await this.$swal({
              text: `成功解鎖帳戶！`,
              icon: 'question',
              confirmButtonText: 'OK',
              confirmButtonColor: '#d9534f',
            })
            this.info.attempt = 0
            this.$router.go()
          }
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async submit() {
      try {
        let result
        if (this.action === 'edit') {
          const except = 'sepab@lcsd.gov.hk'
          const checkEmail = await this.$axios.$get(
            `users/email/${encodeURIComponent(this.info.email)}`
          )
          if (checkEmail.data.length > 0 && this.info.email !== except) {
            await this.$swal({
              title: this.$t('apply.rejected'),
              text: `電子郵件${this.info.email}已被使用`,
              icon: 'question',
              confirmButtonText: 'OK',
              confirmButtonColor: '#d9534f',
            })
            this.isEditable = false
            return
          } else {
            if (this.info.email === except) {
              const no = await this.$swal({
                title: this.$t('alert.notice'),
                text: `電子郵件${this.info.email}已被使用： 確定要提交？`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: this.$t('alert.no'),
                confirmButtonColor: '#d9534f',
                cancelButtonText: this.$t('alert.yes'),
                cancelButtonColor: '#20b2aa',
              })
              if (no.value) {
                return
              }
            }
            result = await this.$axios.$post(`users/update`, {
              id: this.info.id,
              name: this.info.name,
              password: this.info.password,
              tel: this.info.tel,
              email: this.info.email,
              isSuperAdmin: this.info.isSuperAdmin,
              isSchoolAdmin: this.info.isSchoolAdmin,
              isEditorAdmin: this.info.isEditorAdmin,
              disabled: this.info.disabled,
              school: this.info.school,
              role:
                this.info.isEditorAdmin || this.info.isSuperAdmin
                  ? this.info.role
                  : null,
              remarks: this.info.remarks,
            })

            result = await this.$axios.$post(
              `${process.env.cloudApiUrl}users/updateCloudInfo`,
              {
                id: this.info.id,
                name: this.info.name,
                email: this.info.email,
                isSuperAdmin: this.info.isSuperAdmin,
                isSchoolAdmin: this.info.isSchoolAdmin,
                isEditorAdmin: this.info.isEditorAdmin,
                disabled: this.info.disabled,
                school: this.info.school,
                role:
                  this.info.isEditorAdmin || this.info.isSuperAdmin
                    ? this.info.role
                    : null,
                remarks: this.info.remarks,
              }
            )
          }
        } else {
          const except = 'sepab@lcsd.gov.hk'
          const checkEmail = await this.$axios.$get(
            `users/email/${encodeURIComponent(this.info.email)}`
          )
          if (checkEmail.data.length > 0 && this.info.email !== except) {
            await this.$swal({
              title: this.$t('apply.rejected'),
              text: `電子郵件${this.info.email}已被使用`,
              icon: 'question',
              confirmButtonText: 'OK',
              confirmButtonColor: '#d9534f',
            })
            this.isEditable = false
            return
          } else {
            if (this.info.email === except) {
              const no = await this.$swal({
                title: this.$t('alert.notice'),
                text: `電子郵件${this.info.email}已被使用： 確定要提交？`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: this.$t('alert.no'),
                confirmButtonColor: '#d9534f',
                cancelButtonText: this.$t('alert.yes'),
                cancelButtonColor: '#20b2aa',
              })
              if (no.value) {
                return
              }
            }
            result = await this.$axios.$post(`users/create`, {
              id: this.info.id,
              name: this.info.name,
              password: this.info.password,
              tel: this.info.tel,
              email: this.info.email,
              isSuperAdmin: this.info.isSuperAdmin,
              isSchoolAdmin: this.info.isSchoolAdmin,
              isEditorAdmin: this.info.isEditorAdmin,
              disabled: this.info.disabled,
              school: this.info.school,
              role:
                this.info.isEditorAdmin || this.info.isSuperAdmin
                  ? this.info.role
                  : null,
              remarks: this.info.remarks,
            })
          }
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/user'))
        }

        this.isEditable = false
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
</style>
